import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import { onAuthStateChanged, getAuth } from "firebase/auth";

// Create a context
const OrgStateContext = createContext();

// Create a provider component
export const OrgStateProvider = ({ children }) => {
  const [curOrg, setCurOrg] = useState(() => {
    const storedCurOrg = sessionStorage.getItem('curOrg');
    return storedCurOrg ? JSON.parse(storedCurOrg) : {};
  });
  const [orgList, setOrgList] = useState([]);

  const socket = io("https://api.entropi.app", {
    rejectUnauthorized: false,
  });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        let orgQuery = {
          collection: "organizations",
          "members.userID": user.uid,
        };
        socket?.emit("get-mongo", orgQuery);
        const responseHandler = (data) => {
          data = JSON.stringify(data);
          var parsed = JSON.parse(data);
          if (parsed["type"] == "get-org") {
            let orgs = parsed["data"];
            if (orgs.length > 0) {
              setOrgList(orgs);
              setCurOrg((prevCurOrg) => {
                const selectedOrg = orgs.find(org => org.orgName === prevCurOrg.orgName);
                return selectedOrg ? selectedOrg : orgs[0];
              });
              sessionStorage.setItem('curOrg', JSON.stringify(curOrg));
              socket.off("response", responseHandler);
            }
          }
        };
        socket?.on("response", responseHandler);
      }
    })
  }, []);


  return (
    <OrgStateContext.Provider value={{ curOrg, setCurOrg, orgList }}>
      {children}
    </OrgStateContext.Provider>
  );
};

// Create a custom hook to use the global state
export const useOrgState = () => useContext(OrgStateContext);