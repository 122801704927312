import { useState, useEffect } from "react";

export default function Header() {
  const [top, setTop] = useState(true);

  useEffect(() => {
    const scrollHandler = () => setTop(window.scrollY <= 10);
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <header
      className={`fixed w-full z-30 bg-opacity-20 md:bg-opacity-10 transition duration-300 ease-in-out bg-slate-950 h-14 md:h-16 ${
        top ? "backdrop-blur-sm" : "backdrop-blur-xl shadow-lg"
      }`}
    >
      <div className="h-auto w-auto md:max-w-7xl md:mx-auto mx-5 sm:px-6">
        <div className="flex items-center justify-between h-14 md:h-16">
          <div className="flex items-center">
            <img
              src="https://mypeerai.s3.amazonaws.com/entropi-logo.png"
              alt="Entropi Logo"
              className="h-8 w-auto mt-0.5"
            />
          </div>
          <nav className="hidden md:flex md:grow justify-center">
            <ul className="flex justify-center flex-wrap items-center space-x-4">
              <li>
                <a
                  href="#product"
                  className="text-white font-medium hover:text-gray-300"
                >
                  Product
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="text-white font-medium hover:text-gray-300"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="https://docs.entropi.app/introduction"
                  className="text-white font-medium hover:text-gray-300"
                >
                  Docs
                </a>
              </li>
            </ul>
          </nav>
          <nav className="hidden md:flex justify-end">
            <ul className="flex justify-end flex-wrap items-center space-x-4">
              <li>
                <a
                  href="/login"
                  className="bg-gray-800 text-white font-medium py-2 px-4 rounded hover:bg-gray-600"
                >
                  Login
                </a>
              </li>
              <li>
                <a
                  href="/signup"
                  className="bg-purple-600 text-white font-medium py-2 px-4 rounded hover:bg-purple-400"
                >
                  Sign Up
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <nav className="md:hidden flex grow items-end absolute right-3 top-2">
          <ul className="flex grow justify-end flex-wrap"></ul>
        </nav>
      </div>
    </header>
  );
}
