import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { useParams, useNavigate } from "react-router-dom";
import { useOrgState } from "../../contexts/OrgContext";

import Tooltip from "@material-tailwind/react/components/Tooltip"
import { Spinner } from "@material-tailwind/react"
import Feedback from '../../components/dashboard/Feedback';
import Header from '../../components/app/Header';
import InteractiveBox from '../../components/app/InteractiveBox';
import CreateLinkModal from './components/CreateLinkModal';


export default function Interviews(props) {

    const auth = getAuth(); 
    const user = auth.currentUser
    const productID = useParams()["productID"];
    const socket = props.socket
    const curOrg = props.curOrg
    const navigate = useNavigate()

    const [interviews, setInterviews] = useState([]);
    const [curInterview, setcurInterview] = useState({})
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    function handleNewItem() {
        setIsOpen(true);
    }

    function handleNewInterview(){
        window.location = window.location.toString().substring(0, window.location.toString().indexOf("/interviews"))+"/newInterview"
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const [activeTab, setActiveTab] = useState('Interview');
    const [loader, setLoader] = useState(false)

    const [sims, setSims] = useState([])
    const [curSim, setCurSim] = useState({})

    var ref_id = null;

    if (window.location.toString().includes("?")) {
        ref_id = window.location.toString().substring(window.location.toString().indexOf("?") + 1, window.location.toString().length)
    }

    function handleTabChange(e) {
        setActiveTab(e.target.innerHTML)
    }



    useEffect(() => {
        const interviewQuery = {
            'collection': 'interviews',
            'productID': productID
        };

        const handleResponse = (data) => {
            let parsed;
            if (typeof data === 'string') {
                parsed = JSON.parse(data);
            } else {
                parsed = data;
            }
            if (parsed['type'] === 'get-interviews') {
                setInterviews(parsed['data']);        
                if (ref_id) {
                    const currentInterview = parsed['data'].find(interview => interview.interviewID === ref_id);
                    setcurInterview(currentInterview);
                } else{
                    setcurInterview(parsed['data'][0])
                }
            }
        };

        socket.emit("get-mongo", interviewQuery);
        socket.on('response', handleResponse);

        return () => {
            socket.off('response', handleResponse);
            setLoading(false);
        };
    }, [productID]);


    return (
        <>
            <div className="relative flex flex-col backdrop-blur-xl bg-slate-900 h-[100vh]">
            {
            loading ? 
                <div className="h-full w-full flex items-center justify-center">
                    <Spinner color="blue" size="lg" />
                </div>
            :
            <div>
                <Feedback socket={socket}/>
                <CreateLinkModal showModal={showModal} setShowModal={setShowModal} name={curInterview ? curInterview.interviewName : 'exported'} socket={socket} productID={productID} interviewID={curInterview ? curInterview.interviewID : ''} curOrg={curOrg}/>
                <Header user={user} modal={dropdownVisible} setModal={setDropdownVisible} socket={socket} disableOrgSelect={true}/>
                <div className="flex p-1">
                    <InteractiveBox 
                        socket={socket} 
                        screen="Interviews" 
                        interviews={interviews}
                        selected={curInterview?.interviewName}
                        curItem={curInterview}
                        setCurItem={setcurInterview}
                        setOnClick={handleNewInterview}
                        setShowModal={setShowModal}
                    />
                </div>
            </div>
            }
            </div>
        </>
    )
}
