import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useOrgState } from "../../contexts/OrgContext";

import Tooltip from "@material-tailwind/react/components/Tooltip"
import { Spinner } from "@material-tailwind/react"
import Feedback from '../../components/dashboard/Feedback';
import Header from '../../components/app/Header';
import InteractiveBox from '../../components/app/InteractiveBox';


export default function Profiles(props) {
    const auth = getAuth();
    const user = auth.currentUser
    const productID = useParams()["productID"];
    const socket = props.socket
    const [profiles, setProfiles] = useState([])

    const [curProfile, setCurProfile] = useState({})
    const [newProfile, setNewProfile] = useState(false)
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [collapsed, setCollapsed] = useState(false)
    const [loading, setLoading] = useState(true);

    var ref_id = null;

    if (window.location.toString().includes("?")) {
        ref_id = window.location.toString().substring(window.location.toString().indexOf("?") + 1, window.location.toString().length)
    }

    useEffect(() => {
        var query = {
            'collection': 'profiles',
            'productIDs': { "$in": [productID] }
        };
        socket.emit("get-mongo", query);
        socket.on('response', (data) => {
            data = JSON.stringify(data);
            var parsed = JSON.parse(data);
            if (parsed['type'] === 'get-profile') {
                if (parsed['data'].length > 0) {
                    setProfiles(parsed['data']);
                    if (ref_id) {
                        const foundProfile = parsed['data'].find(prof => prof.profileID === ref_id);
                        if (foundProfile) {
                            setCurProfile(foundProfile);
                        }
                    } else {
                        setCurProfile(parsed['data'][0]);
                    }
                } else {
                    setProfiles([]);
                    setCurProfile({});
                }
            }
            setLoading(false); // Move setLoading here
        });
        return () => socket.off('response');
    }, []);



    return (
        <>
            <div className="relative flex flex-col backdrop-blur-xl bg-slate-900 h-[100vh]">
            {
            loading ? 
                <div className="h-full w-full flex items-center justify-center">
                    <Spinner color="blue" size="lg" />
                </div>
            :
            <div>
                <Feedback socket={socket}/>
                <Header user={user} modal={dropdownVisible} setModal={setDropdownVisible} socket={socket} disableOrgSelect={true}/>
                <div className="flex p-1">
                    <InteractiveBox 
                        socket={socket} 
                        type={'profile'} 
                        screen="Profiles" 
                        selected={curProfile ? curProfile.firstName + " " + curProfile.lastName : ''} 
                        name={curProfile ? curProfile.firstName + " " + curProfile.lastName : ''} 
                        newProfile={newProfile} 
                        itemID={curProfile?.profileID}
                        profiles={profiles}
                        curItem={curProfile}
                        setCurItem={setCurProfile}
                        setOnClick={setNewProfile}
                    />
                </div>
            </div>
            }
            </div>
        </>
    )
}
