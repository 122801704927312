import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBRhaDgnMqB1wE9iyP20QTXS1cscNS9Ims",
  authDomain: "entropi-d094e.firebaseapp.com",
  projectId: "entropi-d094e",
  storageBucket: "entropi-d094e.appspot.com",
  messagingSenderId: "552670862188",
  appId: "1:552670862188:web:81664ab0d690c4dd19b149",
  measurementId: "G-BPDGL2RHC6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export default auth;