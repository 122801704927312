import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react';


export default function DeleteItem(props){
    const messageMap = {
        'interviews' : {
            'mainText' : "Are you sure you want to delete this interview?",
            'subText' : "This will also delete any associated interview links."
        },
        'profiles' : {
            'mainText' : "Are you sure you want to delete this Profile?",
            'subText' : ""
        },
        'reset_account' : {
            'mainText' : "Are you sure you want to reset your account?",
            'subText' : "This will delete all products and any associated interviews or profiles."
        },
        'delete_account' : {
            'mainText' : "Are you sure you want to delete your account?",
            'subText' : "This will delete your account including all products and any associated interviews or profiles."
        },
        'leave_org': {
            'mainText': "Are you sure you want to leave this organization?",
            'subText': "This will remove your access to all products under this organization."
        }
    }
    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={props.closeModal}>
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
                    <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-slate-800 shadow-xl rounded-2xl">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                            Confirm Deletion
                        </Dialog.Title>
                        <div className="mt-2">
                            <p className="text-sm font-medium text-gray-300 my-1 py-1">
                                {messageMap[props.action].mainText} <br/><br/> {messageMap[props.action].subText} This action cannot be <span className="text-red-400 font-bold">undone</span>. 
                            </p>
                        </div>
                        <div className="mt-4">
                            <input
                                id="deleteConfirmation"
                                type="text"
                                placeholder="Enter 'permanently delete'"
                                className="text-sm p-2 my-1 w-[95%] text-gray-300 bg-black bg-opacity-40 border-transparent rounded-md"
                            />
                        </div>
                        <div className="mt-4 flex flex-wrap gap-3">
                            <button
                                type="button"
                                className="inline-flex justify-center px-2 py-1 text-xs font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                onClick={() => {
                                    const input = document.getElementById('deleteConfirmation');
                                    if (input && input.value === 'permanently delete') {
                                        props.handleDelete();
                                    } else {
                                        alert('You must type "permanently delete" to confirm deletion.');
                                    }
                                }}
                            >
                                Delete
                            </button>
                            <button type="button" className="inline-flex justify-center px-2 py-1 text-xs font-medium text-gray-300 bg-slate-700 border border-transparent rounded-md hover:bg-slate-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500" onClick={props.closeModal}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}