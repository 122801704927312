import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import XCircleOutline from '@heroicons/react/24/outline/XCircleIcon';
import XCircleSolid from '@heroicons/react/24/solid/XCircleIcon';
import Avvvatars from 'avvvatars-react'

export default function OrgMember(props) {
    const socket = props.socket
    const auth = getAuth();
    const user = auth.currentUser
    const member = props.member
    const isEditing = props.isEditing
    const memberDetails = props.memberDetails
    const [isHovering, setIsHovering] = useState(false)

    function handleHoverChange(){
        setIsHovering(!isHovering)
    }

    function handleDeleteMember(){
        var query = {
            'collection' : 'organizations',
            'query': {
                'orgID': props.orgID
            },
        }
        // If just an invited member
        if(member.email && member.userID == ""){
            query["update"] = {
                'pull': {'members': {'email': member.email}}
            }
        }
        // If official member
        else if (member.userID != ""){
            query["update"] = {
                'pull': {'members': {'userID': member.userID}}
            }
        }
        console.log(query)
        socket.emit("update-mongo", query)
    }

    function handleRoleChange(e) {
        let newRole = e.target.value
        //update member's roles in mongo entry for this org
        let updateQuery = {
            'collection': 'organizations',
            'query': {
                'orgID': props.orgID,
                'members.userID': member.userID
            },
            'update': {
                'members.$.role': newRole,
                'push': {}
            }
        }

        socket.emit("update-mongo", updateQuery)

        if (member.userID === user.uid) {
            window.location.reload()
        }
    }

    return (<>
        <div className={`bg-black bg-opacity-40 border-b-2 border-gray-600 p-3 grid ${isEditing ? "grid-cols-[50%_30%_20%]" : "grid-cols-2"} w-[80%]`}>
            <div className="flex flex-row items-center justify-start gap-4 w-full">
                {
                    memberDetails?.imageLink ? <img src={memberDetails.imageLink} className="w-8 h-8 rounded-full object-cover" /> : <Avvvatars size={30} value={member.email && member.userID == "" ? member.email : memberDetails?.firstName?.charAt(0) + memberDetails?.lastName?.charAt(0)} />
                }
                <p>{member.email && member.userID == "" ? member.email : memberDetails?.firstName + " " + memberDetails?.lastName}</p>
            </div>
            <div className="flex flex-row items-center justify-center">
                <select className="p-1 bg-transparent w-[60%] text-gray-300 focus:outline-none" disabled={!isEditing} onChange={handleRoleChange}>
                    {
                        member.role == "Invited" ?
                            <option selected={true}> Invited </option>
                            :
                            <>
                                <option selected={member.role == "Member"}>
                                    Member
                                </option>
                                <option selected={member.role == "Admin"}>
                                    Admin
                                </option>
                            </>
                    }
                </select>
            </div>
            <div className={`${isEditing ? '' : 'hidden'} flex items-center justify-end pr-4`}>
                {isHovering ? <XCircleSolid className="w-6 h-6 text-red-400" onMouseLeave={handleHoverChange} onClick={handleDeleteMember}/> : <XCircleOutline className="w-6 h-6 text-gray-400" onMouseEnter={handleHoverChange}/>}
            </div>
        </div>
    </>)
}