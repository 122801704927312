import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { useOrgState } from "../contexts/OrgContext";
import Tooltip from "@material-tailwind/react/components/Tooltip"
import { Spinner } from "@material-tailwind/react"
import Feedback from '../components/dashboard/Feedback';
import Header from '../components/app/Header';
import InteractiveBox from '../components/app/InteractiveBox';

import BuilderHeader from  "../components/interviews/InterviewBuilderLayout/BuilderHeader";
import BuilderSidebar  from "../components/interviews/InterviewBuilderLayout/BuilderSidebar";
import BuilderViewer  from "../components/interviews/InterviewBuilderLayout/BuilderViewer";


import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "../components/ui/resizeable"


interface AppProps {
    socket: any;
    curOrg: any;
}


export default function NewInterview({ socket, curOrg }: AppProps) {

    const productID = useParams()["productID"];
    const auth = getAuth();
    const user = auth.currentUser
    const [interviewers, setInterviewers] = useState([])
    const [showTooltip, setShowTooltip] = useState(false)
    const [selectedInterviewer, setSelectedInterviewer] = useState()

    
    useEffect(() => {
        var interviewersQuery = {
            'collection': 'interviewers',
            'userID': user?.uid
        }
        socket.emit("get-mongo", interviewersQuery)
        socket.on('response', (data: any) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)
            if (parsed['type'] === 'get-interviewer') {
                setInterviewers(parsed['data']);
            }
        })
    }, [productID])

    return (
        <>
        <Header/>
        <div className="p-2">
            <div className="w-full h-[100vh] bg-cyan-950/50 mt-1">
                <ResizablePanelGroup direction="vertical" className="rounded-lg border">
                    <ResizablePanel defaultSize={7.5}>
                        <ResizablePanelGroup direction="horizontal">
                            <ResizablePanel defaultSize={25} maxSize={25}>
                                <div className="flex h-full w-full items-start py-2 px-4">
                                    <BuilderHeader/>
                                </div>
                            </ResizablePanel>
                            <ResizableHandle />
                            <ResizablePanel>
                                <div className="flex h-full w-full items-center justify-end p-6 pt-5">
                                    <button className="bg-purple-800/50 hover:bg-purple-600/50 hover:scale-105 text-white text-xs font-semibold px-2 py-2 rounded">
                                        Create →
                                    </button>
                                </div>
                            </ResizablePanel>
                            <ResizableHandle />
                        </ResizablePanelGroup>
                    </ResizablePanel>
                    <ResizableHandle disabled />
                    <ResizablePanel>
                        <ResizablePanelGroup direction="horizontal">
                            <ResizablePanel maxSize={25}>
                                <BuilderSidebar />
                            </ResizablePanel>
                            <ResizableHandle withHandle />
                            <ResizablePanel defaultSize={75} className="overflow-y-auto">
                                <BuilderViewer interviewers={interviewers} />
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </div>
        </div>
        </>
    )
}
