import { Avatar, AvatarImage ,AvatarFallback} from "@radix-ui/react-avatar";
import { useEffect } from "react";

export default function DashboardSidebar({
  list,
  setCurItem,
  setOnClick,
  curItem,
  screen,
}) {
  useEffect(() => {
    if (list.length > 0) {
      setCurItem(list[0]);
    }
  }, [list, setCurItem]);

  return (
    <div>
      <div className="flex grid grid-cols-1 overflow-y-auto">
        {screen != "profile" && (
          <div className="m-4 ">
            <div
              className="text-center rounded-[5px] bg-slate-700  backdrop-blur-xl h-min hover:bg-slate-600 hover:font-bold"
              onClick={() => setOnClick()}
            >
              <button
                className="p-1 mb-1 text-xs font-semibold text-violet-100"
                onClick={() => setOnClick()}
              >
                + New {screen}
              </button>
            </div>
          </div>
        )}
        <div>
          <ul className="grid grid-cols-1 gap-y-0 w-full p-4">
            {screen === "profile" ? (
              list.map((profile, index) => (
                <li
                  key={index}
                  className={`p-3 text-xs bg-teal-500/20 hover:bg-teal-400/20 cursor-pointer border border-1 border-white/10 gap-x-2 rounded-md ${
                    curItem === profile ? "font-bold" : ""
                  }`}
                  onClick={() => setCurItem(profile)}
                >
                  <Avatar className="p-2 mr-2 bg-violet-500/50 rounded-full">
                    <AvatarImage
                      src={profile.imageLink || "default_avatar.png"}
                      alt={`${profile.firstName} ${profile.lastName}`}
                    />
                    <AvatarFallback>
                      {profile.firstName[0]}
                      {profile.lastName[0]}
                    </AvatarFallback>
                  </Avatar>
                  {profile.firstName + " " + profile.lastName}
                </li>
              ))
            ) : (
              list.map((interview, index) => (
                <li
                  key={index}
                  className={`p-3 my-1 text-xs bg-teal-500/20 hover:bg-teal-400/20 cursor-pointer border border-1 border-white/10 gap-x-2 rounded-md ${
                    curItem === interview ? "font-bold" : ""
                  }`}
                  onClick={() => setCurItem(interview)}
                >
                  {interview.interviewName}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
