import { cn } from "../../../lib/utils"

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {["Products"].map((item, index) => (
        <a
          key={index}
          href={`/${item.toLowerCase()}`}
          className={`text-sm font-medium ${index === 0 ? '' : 'text-muted-foreground'} transition-colors hover:text-primary hover:font-semibold`}
        >
          {item}
        </a>
      ))}
      {["Docs"].map((item, index) => (
        <a
          key={index}
          href={`https://docs.entropi.app/introduction`}
          className={`text-sm font-medium ${index === 0 ? '' : 'text-muted-foreground'} transition-colors hover:text-primary hover:font-semibold`}
        >
          {item}
        </a>
      ))}
    </nav>
  )
}
