import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon, UserCircleIcon, ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline"
import Tooltip from "@material-tailwind/react/components/Tooltip"

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "../ui/select"
import { useOrgState } from '../../contexts/OrgContext';
import { Spinner } from '@material-tailwind/react';

export default function DashboardHeader(props) {
    const socket = props.socket
    const setCollapse = props.setCollapsed
    const collapsed = props.collapsed

    const { curOrg } = useOrgState()
    const productID = useParams()["productID"];
    const [productList, setProductList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})

    const auth = getAuth();
    const user = auth.currentUser

    const curPage = window.location.toString().includes("profiles") ? "profiles" : "interviews"
    const altPage = window.location.toString().substring(0, window.location.toString().lastIndexOf(curPage)) + (curPage === "profiles" ? "interviews" : "profiles")

    const [showAltIcon, setShowAltIcon] = useState(false)

    function handleMouseEnter() {
        setShowAltIcon(true)
    }

    function handleMouseLeave() {
        setShowAltIcon(false)
    }

    function handleProductChange(productID) {
        let baseURL = window.location.toString().substring(0, window.location.toString().lastIndexOf("/" + curPage) - 36);
        window.location = baseURL + productID + "/" + curPage;
    }

    useEffect(() => {
        var query = {
            'collection': 'products',
            'orgID': curOrg.orgID,
        };
        socket.emit("get-mongo", query);
        const handleResponse = (data) => {
            data = JSON.stringify(data);
            var parsed = JSON.parse(data);
            if (parsed['type'] === 'get-product') {
                setProductList(parsed['data']);
                let productEntry = parsed['data'].find((element) => {
                    return element.productID === productID;
                });
                setSelectedProduct(productEntry);
            }
        };
        socket.on('response', handleResponse);
        return () => {
            socket.off('response', handleResponse);
        };
    }, [socket, productID]);

    return (
            <div className="h-full gap-x-1">
                <div className="flex w-full">
                <div className="">
                    {
                        curPage === "profiles" ?
                            !showAltIcon ? <UserCircleIcon className="w-8 h-8 bg-slate-700 rounded-full text-slate-400 p-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                                :
                                <Tooltip content="Jump to Interviews" className="bg-slate-500 text-xs" placement="right"><ChatBubbleBottomCenterTextIcon className="w-8 h-8 bg-slate-900 rounded-full text-slate-400 p-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => window.location = altPage} /></Tooltip>
                            :
                            !showAltIcon ? <ChatBubbleBottomCenterTextIcon className="w-8 h-8 bg-slate-700 rounded-full text-slate-400 p-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                                :
                                <Tooltip content="Jump to Profiles" className="bg-slate-500 text-xs" placement="right"><UserCircleIcon className="w-8 h-8 bg-slate-900 rounded-full text-slate-400 p-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => window.location = altPage} /></Tooltip>
                    }
                </div>
                <Tooltip content="Switch Products" className="bg-slate-500 text-xs" placement="top">
                    {selectedProduct ? (
                        <Select onValueChange={(value) => {
                            if (selectedProduct.productID !== value) {
                                handleProductChange(value);
                            }
                        }}>
                            <SelectTrigger className="border border-white/5 gap-2 ml-2">
                                {selectedProduct.name}
                            </SelectTrigger>
                            <SelectContent className='border border-white/5'>
                                {productList.map((prod) => (
                                    <SelectItem key={prod.productID} value={prod.productID}>
                                        {prod.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    ) : (
                        <div className="flex justify-center items-center">
                            <Spinner size="sm" />
                        </div>
                    )}
                </Tooltip>
                </div>
            </div>
    )
}