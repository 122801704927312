import Avvvatars from 'avvvatars-react'
import { useState } from 'react'

const StatsBar = (props) => {
    return(
        <div className={`w-4 h-2 rounded-xl ${props.color ? props.color : "bg-gray-400 bg-opacity-30"} px-1`}/>
    )
}

export default function InterviewAgent(props) {
    const item = props.item
    const ratings = Array(5).fill(null)
    return (<>
    {
        item ? <>
        <div className="bg-slate-900 rounded-lg w-fit h-fit text-white">
            <div className="flex flex-col items-center justify-center p-4 py-6">
                <Avvvatars size={80} value={item.interviewerName}/>
                <p className="text-xs font-bold uppercase loose p-2 my-2">{item.interviewerName}</p>
                <p className="text-sm font-medium loose p-2 mt-2 text-gray-300">{item.bio}</p>
                {/* <div className="grid grid-rows-2 w-full pt-4 p-2 gap-2 h-full align-bottom">
                    <div className="grid grid-cols-2 items-center ">
                        <p className="text-white text-opacity-50 text-center text-sm font-semibold">Performance</p>
                        <div className="flex flex-row gap-1">
                        {
                                ratings.map((val, index) => {
                                    let color;
                                    if(item.performance == 5){
                                         color = "bg-green-600"
                                    }
                                    else if(item.performance == 4){
                                         color = "bg-green-300"
                                    }
                                    else if(item.performance == 3){
                                         color = "bg-yellow-400"
                                    }
                                    else if(item.performance == 2){
                                         color = "bg-orange-400"
                                    }
                                    else{
                                        color = "bg-red-500"
                                    }
                                    
                                    if(index < item.performance){
                                        return <StatsBar key={index} color={color}/>
                                    }
                                    else{
                                        return <StatsBar key={index} />
                                    }
                                })
                        }
                            
                        </div>
                    </div>
                    <div className="grid grid-cols-2 items-center">
                        <p className="text-white items-start text-opacity-50 text-center text-sm font-semibold">Status</p>
                        <div className="flex flex-row gap-1">
                            {
                                ratings.map((val, index) => {
                                    let color;
                                    if(item.status == 5){
                                         color = "bg-green-600"
                                    }
                                    else if(item.status == 4){
                                         color = "bg-green-300"
                                    }
                                    else if(item.status == 3){
                                         color = "bg-yellow-400"
                                    }
                                    else if(item.status == 2){
                                         color = "bg-orange-400"
                                    }
                                    else{
                                        color = "bg-red-500"
                                    }

                                    if(index < item.status){
                                        return <StatsBar key={index} color={color}/>
                                    }
                                    else{
                                        return <StatsBar key={index} />
                                    }
                                })
                        }
                        </div>
                    </div>
                </div> */}
                <div id='tags' className="grid grid-cols-2 gap-3 text-[10px] font-bold items-center text-center text-gray-200 justify-between uppercase mt-10">
                        <div className="bg-violet-200/50 rounded-sm px-2">{item.model}</div>
                        {/* <div className="bg-violet-200/50 rounded-sm px-2">{item.role ? item.role : "Interviewer"}</div> */}
                        <div className="bg-violet-200/50 rounded-sm px-1 flex flex-wrap">{item.dateCreated}</div>
                </div>
            </div>
        </div> 
        </>
        :
        <> 
        </>
}
    </>)
}