
import { useAuth } from '../../contexts/AuthContext'
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useOrgState } from "../../contexts/OrgContext.js";

import { MainNav } from '../../pages/app/components/main-nav';
import TeamSwitcher from '../../pages/app/components/team-switcher';
import { UserNav } from '../../pages/app/components/user-nav';

export default function Header(props){
    const auth = getAuth();
    const user = auth.currentUser
  
    const { curOrg, setCurOrg, orgList } = useOrgState()
    const [top, setTop] = useState(true)
    const [modal, setModal] = useState(false);
    const [imageLink, setImageLink] = useState();

    const { logout, setError } = useAuth();
    const navigate = useNavigate();
    const socket = props.socket
  
    async function handleLogout() {
      try {
        setError("");
        await logout();
        setModal(false);
        navigate("/");
      } catch {
        setError("Failed to logout");
      }
    }
  
    function handleOrgChange(e) {
      const selectedOrg = orgList.find(org => org.orgName === e.target.value);
      setCurOrg(selectedOrg);
      sessionStorage.setItem('curOrg', JSON.stringify(selectedOrg));
    }
  
  
    // detect whether user has scrolled the page down by 10px
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
  
    useEffect(() => {
      let userQuery = {
        'collection': 'users',
        'userID': props.user?.uid
      }
      socket?.emit('get-mongo', userQuery)
      const responseHandler = (data) => {
        data = JSON.stringify(data)
        var parsed = JSON.parse(data)
        if (parsed["type"] == "get-user") {
          let thisUser = parsed["data"][0]
          if (thisUser) {
            setImageLink(thisUser.imageLink)
          }
          socket?.off('response', responseHandler)
        }
      }
      socket?.on('response', responseHandler)
      scrollHandler()
      window.addEventListener('scroll', scrollHandler)
      return () => {window.removeEventListener('scroll', scrollHandler)}
  
    }, [top])
  
    function toggleDropdown() {
      props.setModal(!props.modal)
    }

    return (
    <nav className="border-b border-gray-100/5">
        <div className="flex h-16 items-center px-4">
        <div className="flex flex-row items-center justify-center gap-1 mr-5">
          <div className="flex items-center">
            <img
              src="https://mypeerai.s3.amazonaws.com/entropi-logo.png"
              alt="Entropi Logo"
              className="h-6 w-auto mt-0.5"
            />
          </div>
        </div>
        {props.disableOrgSelect ? (
          <div className="brightness-125">
            <TeamSwitcher orgs={orgList} curOrg={curOrg} setCurOrg={setCurOrg} isDropdownDisabled={true} />
          </div>
        ) : (
          <TeamSwitcher orgs={orgList} curOrg={curOrg} setCurOrg={setCurOrg} isDropdownDisabled={false} />
        )}
        <div className="ml-auto flex items-center space-x-1">
            <MainNav className="mx-6" />
            <UserNav user={user.displayName} email={user.email} image={user.photoURL ? user.photoURL : imageLink} />
        </div>
        </div>
    </nav>
  );
};

