import { useState, useEffect, Fragment} from 'react';
import Avvvatars from 'avvvatars-react'

export default function ProfileBank(props){
    const interviewID = props.interviewID
    const setSelected = props.setSelectedProfile
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [dateFilter, setDateFilter] = useState(null);
    const [sentimentFilter, setSentimentFilter] = useState(null);
    const [interviewCountFilter, setInterviewCountFilter] = useState(null);
    const [sentimentValue, setSentimentValue] = useState(1);
    const [displayList, setDisplayList] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    
    useEffect(() => {
        setDisplayList(props.items)
        if(props.items.length > 0)
            setSelected(props.items[0])
        else
            setSelected({})
    }, [JSON.stringify(props.items)])

    useEffect(() => {
        setFiltersVisible(false)
        setSentimentValue(1)
        setSentimentFilter(1)
        setInterviewCountFilter(null)
        setDateFilter(null)
    }, [JSON.stringify(props.curInterview), JSON.stringify(props.items)])

    useEffect(() => {
            let filteredItems = props.items;

            if (dateFilter != null) {
                filteredItems = filteredItems.filter(profile => new Date(profile.metadata.acquired_date) >= new Date(dateFilter));
            }
        
            if (sentimentFilter != null) {
                filteredItems = filteredItems.filter(profile => profile.metadata.sentiment >= sentimentFilter);
            }
        
            if (interviewCountFilter != null) {
                filteredItems = filteredItems.filter(profile => profile.metadata.interview_count >= interviewCountFilter);
            }

            setDisplayList(filteredItems);

            if (filteredItems.length > 0) {
                setSelected(filteredItems[0]);
            }
            else{
                setSelected({})
            }
    }, [dateFilter, sentimentFilter, interviewCountFilter]); 

    return(
        <>
        <div id="filtersToggle" className="flex justify-between itemsss-center px-5" onClick={() => setFiltersVisible(!filtersVisible)}>
            <p className="text-sm font-medium text-white text-opacity-60 pb-5">Filters</p>
            <svg className={`transform ${filtersVisible ? 'rotate-180' : 'rotate-0'} text-gray-200`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="20" height="20">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
        </div>
        {filtersVisible && 
            <div id="filters" className="grid grid-cols-1 gap-5 mb-10 text-xs p-2 px-5">
                <div className="flex gap-5">
                    <div className="flex flex-col">
                        <label htmlFor="dateFilter" className="text-gray-400 ml-1">Date:</label>
                        <input type="date" id="dateFilter" name="dateFilter" className="date-input bg-black bg-opacity-20 text-gray-500 rounded-md my-2 p-1" value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}/>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="interviewCountFilter" className="text-gray-400 ml-1">Interview Count:</label>
                        <input type="number" id="interviewCountFilter" name="interviewCountFilter" className="bg-black bg-opacity-20 text-white rounded-md my-2 p-1 w-3/4" value={interviewCountFilter} onChange={(e) => setInterviewCountFilter(e.target.value)} />
                    </div>
                </div>
                <div className="flex flex-col w-1/4">
                    <label htmlFor="sentimentFilter" className="text-gray-400 ml-1">Sentiment:</label>
                    <div className="flex items-center gap-3">
                        <input type="range" id="sentimentFilter" name="sentimentFilter" min="1" max="5" className="ml-1 bg-black bg-opacity-20 text-white rounded-md p-2" value={sentimentValue} onChange={(e) => {setSentimentValue(e.target.value); setSentimentFilter(e.target.value)}} />
                        <output id="sentimentOutput" htmlFor="sentimentFilter" value={sentimentFilter} className="text-gray-400 px-3 my-1 bg-black bg-opacity-30 rounded-md">{sentimentValue}</output>
                    </div>
                </div>
            </div>
        }
        <div className="flex flex-col flex-wrap bg-black bg-opacity-20 rounded-xl p-6 shadow-xl">
            <div className="flex flex-wrap gap-5">
                {
                    displayList.length > 0 ? 
                    
                    displayList.map((profile, index) => {
                        return <button key={index} onClick={() => props.setSelectedProfile(profile)}><Avvvatars size={50} value={profile.firstName + profile.lastName}/></button>
                    })

                    :
                    <p className="text-center text-sm font-medium text-white text-opacity-60 w-full">{(dateFilter != null || sentimentFilter != 1 || interviewCountFilter != null) ? "No matching results." : "No responses have been collected yet, please check back later!"}</p>
                }
            </div>
        </div>
        </>
    );
};