import React from 'react';
import logo from './logo.svg';
import { getAuth } from "firebase/auth";
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { useOrgState, OrgStateProvider } from "./contexts/OrgContext";
import RequireAuth from "../src/components/accounts/WithPrivateRoute"
import { io } from "socket.io-client";
import Landing from './pages/landing/Landing';
import Login from './pages/auth/Login';
import Products from './pages/app/Products';
import Profiles from './pages/app/Profiles';
import Interviews from './pages/app/Interviews';
import NewInterview from './pages/NewInterview';

import Onboarding from "./pages/onboarding/Onboarding";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Settings from "./pages/settings/Settings"
import AcceptOrgInvite from "./pages/auth/AcceptOrgInvite";


function MemoRedirect() {
  useEffect(() => {
    window.location.href = 'https://entropi-app.notion.site/Entropi-Preseed-Memo-fc416a13dc304f3aa4dabeb943cd506f?pvs=4';
  }, []);
  return null;

}

function App() {
  const [orgList, setOrgList] = useState([]);
  const { curOrg, setCurOrg } = useOrgState() || {};
  const auth = getAuth();
  const user = auth.currentUser
  
  const socket = io('https://api.entropi.app', {
    rejectUnauthorized: false
  })

  return (
    <AuthProvider>
    <OrgStateProvider>
      <Router>
        <Routes>
          <Route path='' element={<Landing socket={socket}/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/products' element={<Products socket={socket}/>} />
          <Route path='/:productID/profiles' element={<RequireAuth> <Profiles socket={socket} curOrg={curOrg}/> </RequireAuth>} />
          <Route path='/:productID/interviews' element={<RequireAuth> <Interviews socket={socket} curOrg={curOrg}/> </RequireAuth>} />
          <Route path='/:productID/newInterview' element={<RequireAuth> <NewInterview socket={socket} curOrg={curOrg}/> </RequireAuth>} />
          <Route path='/onboarding' element={<RequireAuth> <Onboarding socket={socket} /> </RequireAuth>} />
          <Route path='/settings' element={<RequireAuth> <Settings socket={socket} orgs={orgList} curOrg={curOrg} setCurOrg={setCurOrg} /> </RequireAuth>} />
          <Route path='/orgs/:orgID/join' element={<RequireAuth> <AcceptOrgInvite socket={socket} /> </RequireAuth>} />
          <Route path='/memo' element={<MemoRedirect />} />
        </Routes>
      </Router>
      </OrgStateProvider>
    </AuthProvider>
  );
}


export default App;
