import { useState, useRef, forwardRef, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { noop } from 'lodash';
const uuid = require('uuid')

const FormTextField = forwardRef((props, ref) => {
    return (<>
        <div className={props.className} >
            <div className={`flex flex-row items-center justify-center gap-8 pb-14`} id={props.id}>
                <div className="rounded-full bg-indigo-400 w-10 h-10 flex items-center justify-center" >
                    <p className="text-gray-300 text-3xl font-medium">{props.index}</p>
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-white text-3xl font-medium">{props.fieldTitle}</p>
                    <input type="text" ref={ref} className="bg-slate-800 rounded-md p-2 text-gray-200 font-medium text-xl" onChange={(e) => props.setFieldVar(e.target.value)} onKeyDown={(e) => { if (e.keyCode === 13) props.onNext() }} />
                </div>
            </div>
        </div>
    </>)
})

const FormTextArea = forwardRef((props, ref) => {
    return (<>
        <div className={props.className} >
            <div className={`flex flex-row items-center justify-center gap-8`} id={props.id}>
                <div className="rounded-full bg-indigo-400 w-10 h-10 flex items-center justify-center" >
                    <p className="text-gray-300 text-3xl font-medium">{props.index}</p>
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-white text-3xl font-medium ">{props.fieldTitle}</p>
                    <div className="flex flex-row">
                        <textarea type="text" ref={ref} className="bg-slate-800 rounded-md p-2 text-gray-200 font-medium text-xl max-h-36 min-w-[180%]" onChange={(e) => props.setFieldVar(e.target.value)} onKeyDown={(e) => { if (e.keyCode === 13) props.onNext() }} />
                    </div>
                </div>
                {/* <ArrowUturnLeftIcon className="w-6 h-8 transform -scale-y-100 text-gray-300" onClick={props.onNext} /> */}
            </div>
        </div>
    </>)
})

const FormRadioField = forwardRef((props, ref) => {
    return (<>
        <div className="flex flex-row items-center justify-start gap-8 pb-14" >
            <div className="rounded-full bg-indigo-400 w-10 h-10 flex items-center justify-center">
                <p className="text-gray-300 text-3xl font-medium">{props.index}</p>
            </div>
            <div>
                <p className="text-white text-3xl font-medium">{props.fieldTitle}</p>
                <div className="flex flex-row gap-5 mt-3" >
                    {
                        props.options.map((value, index) => {
                            return (<>
                                <div className={`flex flex-row items-center justify-center ${props.fieldVar === value ? "bg-slate-600" : "bg-slate-800"} p-1 px-2 rounded-lg gap-2`} onClick={() => { props.setFieldVar(value); props.onNext() }} key={index}>
                                    <input type="radio" checked={props.fieldVar === value} onChange={() => props.setFieldVar(value)} className="" ref={index === 0 ? ref : null} />
                                    <p className={`text-lg font-medium ${props.fieldVar === value ? "text-gray-200" : "text-gray-400"}`}>{value}</p>
                                </div>
                            </>)
                        })
                    }
                </div>
            </div>
        </div>
    </>)
})



export default function OnboardingForm(props) {
    const socket = props.socket
    const auth = getAuth();
    const user = auth.currentUser
    const onNext = props.next

    //Fields
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [company, setCompany] = useState("")
    const [selectedRole, setSelectedRole] = useState("")
    const [interest, setInterest] = useState("")
    const [leftOffset, setLeftOffset] = useState(null)

    const refMap = {
        'firstName': useRef(null),
        'lastName': useRef(null),
        'company': useRef(null),
        'role': useRef(null),
        'interest': useRef(null)
    }

    function handleNext() {
        // Save inputs
        if (firstName != "" && firstName != " " && lastName != "" && lastName != " " && company != "" && company != " " && selectedRole != "" && selectedRole != " " && interest != "" && interest != " ") {
            let orgID = uuid.v4().toString()
            let userData = {
                'collection': 'users',
                'userID': user.uid,
                'firstName': firstName,
                'lastName': lastName,
                'email': user.email,
                'company': {
                    'name': company,
                    'role': selectedRole
                },
                'interest': interest,
                'orgs':[{"orgId" : orgID, role: "admin"}]
            }
            socket.emit('add-mongo', userData)

            let orgData = {
                'collection': 'organizations',
                'orgID': orgID,
                'orgName': company.charAt(0).toUpperCase()+company.substring(1),
                'members': [{'userID': user.uid, 'role': 'admin'}]
            }

            socket.emit('add-mongo', orgData)
            onNext()
        }
        else{
            alert("Please ensure all fields are complete")
        }
    }

    function handleFieldComplete(key) {
        let scrollRef = refMap[key]
        scrollRef.current?.focus()
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function handleScroll(event) {
        event.preventDefault()
    }

    useEffect(() => {
        const element = document.getElementById('firstName'); // Replace with your actual element ID or use another selector method
        const rect = element.getBoundingClientRect();
        console.log(rect.left)
        setLeftOffset(rect.left)
    })



    return (<>
        <div className="grid grid-cols-1 w-full h-full overflow-y-hidden" onKeyDown={handleKeyDown} onScroll={handleScroll}>
            <div className="mt-[3rem] h-fit">
                <p className="text-6xl text-white text-center font-bold ">Before we start, let's learn a little bit about you.</p>
                <p className="text-xl text-gray-500 text-center font-medium pt-2">All fields are required</p>
            </div>
            <div className="flex flex-col overflow-y-auto -mt-[2.5rem] scrollbar-none mx-20" onKeyDown={handleKeyDown}>
                <div className="flex flex-row items-center justify-center gap-16 ">
                    <FormTextField index={1} id="firstName" className="" fieldTitle={"First Name"} setFieldVar={setFirstName} onNext={() => handleFieldComplete("lastName")} ref={refMap["firstName"]} />
                    <FormTextField index={2} fieldTitle={"Last Name"} setFieldVar={setLastName} onNext={() => handleFieldComplete("company")} ref={refMap["lastName"]} />
                    <FormTextField index={3} fieldTitle={"Company"} setFieldVar={setCompany} onNext={() => handleFieldComplete("role")} ref={refMap["company"]} />
                </div>
                <div className="mt-3">
                    <div className="mx-[3.5rem] px-[3rem]">
                        <FormRadioField index={4} fieldTitle={company != "" ? "Which option best describes your role at " + company + "?" : "Which option best describes your role at work?"} options={["Founder", "Product Management", "User Research", "Marketing", "Development"]} setFieldVar={setSelectedRole} fieldVar={selectedRole} onNext={() => handleFieldComplete("interest")} ref={refMap["role"]} />
                    </div>
                    <div className="w-fit mx-[3.5rem] px-[3rem] mt-3">
                        <FormTextArea index={5} fieldTitle={"What interests you about Entropi?"} setFieldVar={setInterest} onNext={() => handleFieldComplete("interest")} ref={refMap["interest"]} />
                    </div>
                </div>
            </div>
            <div className="relative">
                <div className="absolute right-0 bottom-0">
                    <button onClick={handleNext} className="text-white text-3xl pb-10 mr-10 mb-10">→</button>
                </div>
            </div>
        </div>
    </>)
}