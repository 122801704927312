import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import ProfileCard from './ProfileCard';
import InterviewAgent from './InterviewAgent';

import { PencilIcon } from "@heroicons/react/24/solid";
import ProfileBank from './ProfileBank';


export default function InterviewViewer(props) {
  const auth = getAuth();
  const user = auth.currentUser;
  const socket = props.socket;
  var curItem = props.curItem

  const [interviewer, setInterviewer] = useState({});
  const [editing, setEditing] = useState(false);
  const [guidelines, setGuidelines] = useState(curItem.guidelines);
  const [pendingInterviews, setPendingInterviews] = useState(0);
  const [incompleteInterviews, setIncompleteInterviews] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [profiles, setProfiles] = useState([])
  var x = 1

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    var query = {
        'collection': 'interviews',
        'query': {
            'interviewID': curItem.interviewID,
        },
        'update': {
            guidelines: guidelines,
        }
    }

    if(curItem){
        socket.emit("update-mongo", query)
    }
    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

    
  useEffect(() => {
    if (curItem.guidelines){
        setGuidelines(curItem.guidelines)
    }
    
    var query = {
        'collection': 'interviewers',
        'jobs': {"$in": [curItem ? curItem.interviewID : '']}
    }
    socket.emit("get-mongo", query)

    var linkQuery = {
        'collection': 'links',
        'interviewID': curItem.interviewID
    }

    if (JSON.stringify(curItem) != "{}"){
        socket.emit("get-mongo", linkQuery)
    }

    const responseHandler = (data) => {
        data = JSON.stringify(data)
        var parsed = JSON.parse(data)
        if (parsed['type'] === 'get-interviewer') {
            setInterviewer(parsed['data'][0])
        }

        if (parsed['type'] === 'get-links') {
            var linkHistory = parsed['data']
            var pendingLinks = linkHistory.filter(item => item.status == "unopened").length;
            var incompleteLinks = linkHistory.filter(item => item.status == "started" || item.status == "opened").length;
            setPendingInterviews(pendingLinks);
            setIncompleteInterviews(incompleteLinks);
            var linkIDs = linkHistory.map(item => item.linkID);
            var profileQuery = {
                'collection': 'profiles',
                'history.id': { "$in": linkIDs }
            }
            if (linkIDs.length > 0)
                socket.emit("get-mongo", profileQuery)
            else
                setProfiles([])
            
        }
        if (parsed['type'] === 'get-profile') {
            setProfiles(parsed['data'])
        }
    }

    socket.on('response', responseHandler)
}, [JSON.stringify(props.curItem)]);


  return (
    <>
    <div id="interviewView" className="w-full h-full p-5 m-auto overflow-x-hidden grid grid-cols-1 gap-y-2 divide-y divide-dashed divide-white divide-opacity-10">
        <div className="grid grid-cols-1">
            <p className="text-xl font-semibold text-white text-opacity-80 m-4 my-1 p-4">Details</p> 
            <div className="grid grid-cols-[30%_60%] gap-x-5 align-items-center h-fit">
                <div className="grid grid-cols-1 w-full h-full p-4 ml-4 mt-4">
                        <InterviewAgent item={interviewer}/>
                </div>
                <div className="grid grid-cols-1 w-full h-min mx-4 px-4 mt-4 pt-4">
                    <div className="w-auto h-min">
                        <div className="grid grid-rows-2 items-start gap-6">
                            <div className="w-full h-full bg-slate-900 text-white rounded-lg shadow-md p-4 overflow-y grid grid-cols-1 relative">
                                <div className="flex flex-wrap mx-2 w-full">
                                    <p className="text-sm text-gray-400 font-medium ">Guidelines</p>
                                </div>
                                {editing ? (
                                    <>
                                    <textarea
                                        className="loose text-sm font-medium text-gray-200 p-2 mx-auto bg-slate-900 w-[95%] p-2 m-2 mx-4"
                                        value={guidelines}
                                        onChange={(e) => setGuidelines(e.target.value)}
                                    />
                                    <div className="flex flex-wrap gap-2 justify-end mt-2">
                                        <button className="text-sm font-medium text-gray-300" onClick={handleSaveClick}>Save</button>
                                        <button className="text-sm font-medium text-gray-300" onClick={handleCancelClick}>Cancel</button>
                                    </div>
                                    </>
                                ) : (
                                    <h3 className="loose text-start text-sm font-medium text-gray-200 p-2 mx-auto line-clamp-4 h-[90%]">{guidelines}</h3>
                                )}
                                <div className="grid grid-cols-1 mx-2 absolute top-3 right-2">
                                    <PencilIcon className="w-4 h-4 text-sm mx-auto" onClick={handleEditClick} />
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-6 shadow-xl">
                                    <p className="pt-5 text-lg font-medium text-white text-opacity-90">{curItem && curItem.details ? curItem.details.interviewsCompleted : "N/A"}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Completed</p>
                                </div>
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-6 shadow-xl">
                                    <p className="pt-5 text-lg font-medium text-white text-opacity-90">{pendingInterviews}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Pending</p>
                                </div>
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-6 shadow-xl">
                                    <p className="pt-5 text-lg font-medium text-white text-opacity-90">{incompleteInterviews}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Incomplete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col m-4 p-4">
            <p className="text-xl font-semibold text-white text-opacity-80">Respondents</p>
            <div className="grid grid-cols-2">
                <div className="pt-8">
                    <ProfileBank items={profiles} setSelectedProfile={setSelectedProfile} curInterview={props.curItem}/>
                </div>
                <div>
                    <ProfileCard interviewID={curItem ? curItem.interviewID : ''} selectedProfile={selectedProfile} socket={socket}/>
                </div>
            </div>
        </div>
    </div>
  </>
  );
}