import Avvvatars from 'avvvatars-react'


export default function HowItWorks(props){
    const onNext = props.next;


    const steps = {
        "1" : {
            name: "Add a Product",
            desc: "Tell us what you're working on. A detailed description helps Entropi work better.",
        },
        "2" : {
            name: "Configure an Interviewer",
            desc: "Set up an Interviewer or let Eve from Entropi represent you. Provide guidelines for the interview, including any specific insights you'd like to gain",
        },
        "3" : {
            name: "Invite Customers",
            desc: "Provision unique links to your interview and share them with customers.",
        },
        "4" : {
            name: "Understand Your Audience",
            desc: "Dive into your customers' responses and refine your backlog based on their feedback ",
        }
    }

    return(
        <div className="grid grid-cols-1 gap-y-5 w-full items-center h-full">
            <div className="text-white mx-auto mt-[3rem]">
                <h1 className="text-6xl font-bold ">How it Works</h1>
            </div>
            <div className="text-white mx-auto w-[65vw] mt-5">
                {Object.keys(steps).map((key) => (
                    <div className="m-1 p-2 gap-3 flex" key={key}>
                        <div className="m-4 p-2">
                            <div className="rounded-full text-3xl w-10 h-10 bg-indigo-400 text-gray-300 items-center grid grid-cols-1"><p className="mx-auto">{key}</p></div>
                        </div>
                        <div>
                            <h2 className="text-4xl pb-1 font-medium">{steps[key].name}</h2>
                            <p className="text-xl text-gray-400 py-2">{steps[key].desc}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="relative">
                <div className="absolute right-0 bottom-0">
                    <button onClick={onNext} className="text-white text-3xl pb-10 mr-10">→</button>
                </div>
            </div>
        </div>
    )
}