import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";

import TabView from './components/TabView';
import { Spinner } from "@material-tailwind/react"

//temp imports
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline"
import Header from '../../components/app/Header'

export default function Settings(props) {
    const auth = getAuth();
    const user = auth.currentUser
    const socket = props.socket
    const orgs = props.orgs
    const [loading, setLoading] = useState(true);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        if (user && user.photoURL) {
            setLoading(false);
        }
    }, [user]);


    return (
        <>
        <div className="relative flex flex-col backdrop-blur-xl bg-slate-900 h-[100vh] overflow-y-auto scrollbar scrollbar-none">
        {
            loading ? 
            <div className="h-full w-full flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
            </div>
            :
            <div>
                <div className="">
                    <Header user={user} modal={dropdownVisible} setModal={setDropdownVisible} socket={socket} disableOrgSelect={true}/>
                </div>
                <div className="h-full" onClick={() => { setDropdownVisible(false) }}>
                    <div className="mt-10 px-10 w-full">
                        <div className="flex flex-row gap-1 items-center justify-start">
                            <AdjustmentsVerticalIcon className="w-7 h-7 text-violet-100"/>
                            <h2 className="text-4xl font-bold text-gray-200">Settings</h2>
                        </div>
                    </div>
                    <div className="p-5">
                        <TabView socket={socket} curOrg={props.curOrg}/>
                    </div>
                </div>
            </div>
        }
        </div>
        </>
    )
}