import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import { QuestionMarkCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

export default function Feedback(props) {
    const socket = props.socket
    const auth = getAuth();
    const user = auth.currentUser
    const [showForm, setShowForm] = useState(false)
    const [feedbackText, setFeedbackText] = useState('')
    const [followUp, setFollowUp] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    function submitFeedback() {
        var dataPack = {
            'userID': user.uid,
            'feedback': feedbackText,
            'followUp': followUp
        }
        socket.emit('feedback', dataPack)
        setFormSubmitted(true)
        setTimeout(() => {
            setFormSubmitted(false)
            setShowForm(false)
        }, 1000)
    }

    return (<>
        {
            showForm ?
                <div className="absolute bottom-0 right-0 m-3 overflow-none">
                    <div className="flex flex-col relative rounded-md w-[17rem] h-auto bg-gray-800 p-3 overflow-none">
                        {
                            formSubmitted ?
                                <div className="flex items-center justify-center">
                                    <CheckCircleIcon className="w-8 h-8 text-green-600"/>
                                </div>
                                :
                                <>
                                    <button className="absolute text-gray-500 top-0 right-0 text-sm m-1 mr-2" onClick={() => setShowForm(false)}>x</button>
                                    <p className="text-white text-opacity-90 font-semibold">Quick Feedback</p>
                                    <textarea className="w-full mt-3 max-h-36 text-sm text-gray-300 bg-opacity-20 bg-black rounded-md p-1" placeholder={"Feel free to leave any feedback or suggestions to help us improve Entropi"} onChange={(e) => setFeedbackText(e.target.value)}/>
                                    <p className="mt-3 text-sm text-gray-200 font-medium">Would you like a follow up?</p>
                                    <div className="flex flex-row gap-3 mt-2 mb-4">
                                        <button className={`text-sm font-medium rounded-md p-1 bg-black hover:bg-opacity-40 hover:text-white ${followUp ? "bg-opacity-40 text-white" : "bg-opacity-10 text-gray-400"} w-10`} onClick={() => setFollowUp(true)}>Yes</button>
                                        <button className={`text-sm font-medium rounded-md p-1 bg-black hover:bg-opacity-40 hover:text-white ${!followUp ? "bg-opacity-40 text-white" : "bg-opacity-10 text-gray-400"} w-10`} onClick={() => setFollowUp(false)}>No</button>
                                    </div>
                                    <div>
                                        <button className="text-sm absolute bottom-0 right-0 m-2 text-white rounded-md p-1 px-2 bg-black bg-opacity-20 hover:bg-opacity-40" onClick={submitFeedback}>→</button>
                                    </div>
                                </>
                        }
                    </div>
                </div>

                :

                <div className="absolute bottom-0 right-0 m-3">
                    <div>
                        <QuestionMarkCircleIcon className="w-8 h-8 text-white text-opacity-80" onClick={() => setShowForm(true)} />
                    </div>
                </div>
        }
    </>
    )
}