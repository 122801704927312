import { useState, useEffect, useMemo } from 'react'
import Loader from '../../../components/app/Loader'
import AWS from 'aws-sdk'
import Avvvatars from 'avvvatars-react'
import DeleteItem from '../../../components/dashboard/DeleteItem';
import { getAuth } from "firebase/auth";
import { useOrgState } from "../../../contexts/OrgContext";
import { ExclamationTriangleIcon, ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline"
import Tooltip from '@material-tailwind/react/components/Tooltip';
import { LockClosedIcon, LockOpenIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import OrgMember from './OrgMember'
import OrgInvite from './OrgInvite';
import { noop } from 'lodash';


export default function Organization(props) {
    const auth = getAuth();
    const user = auth.currentUser
    const socket = props.socket
    const { curOrg, orgList } = useOrgState()
    const [isOrgAdmin, setIsOrgAdmin] = useState(false)
    const [memberDetails, setMemberDetails] = useState([])

    const [isEditing, setIsEditing] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [imageLink, setImageLink] = useState("")
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const [orgName, setOrgName] = useState("");
    const [membership, setMembership] = useState({});
    const [inviteMembers, setInviteMembers] = useState(false);
    const [leavingOrg, setLeavingOrg] = useState(false);


    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    })

    const s3 = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME }
    })

    function uploadFile() {
        if (selectedFile != null) {
            var extension = '.' + selectedFile.name.split('.').pop();
            var fileName = Math.random().toString(36).substring(7) + new Date().getTime() + extension;

            var params = {
                Key: fileName,
                Body: selectedFile,
                ContentType: selectedFile.type
            }
            setUploadInProgress(true)
            s3.putObject(params, (err, data) => {
                if (err) {
                    console.error("File was not uploaded successfully")
                }
                else {
                    setUploadInProgress(false)
                    setImageLink(`https://mypeerai.s3.amazonaws.com/${encodeURIComponent(fileName)}`)
                    setSelectedFile(null)
                }
            })
        }
    }
    function handleLeaveOrg(){
        var query = {
            'collection' : 'organizations',
            'query': {
                'orgID': curOrg.orgID
            },
            'update': {
                'pull': {'members': {'userID': user.uid}}
            }
        } 
        socket.emit("update-mongo", query)
        setLeavingOrg(false)
        window.location.reload()
    }

    function handleFileChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0])
        }
    }

    function locksmith() {
        if (!isEditing) {
            setIsEditing(true)
        }
        else {
            let updateData = {
                'collection': 'organizations',
                'query': {
                    'orgID': curOrg.orgID
                },
                'update': {
                    'orgName': orgName,
                    'orgImage': imageLink
                }
            }

            socket.emit('update-mongo', updateData)
            setIsEditing(false)
        }
    }

    const cachedAdmin = useMemo(() => {
        return isOrgAdmin;
    }, [isOrgAdmin]);

    useEffect(() => {
        if (curOrg){
            let memberIDs = curOrg?.members?.map((member) => member.userID)

            let memberDetailsQuery = {
                'collection' : 'users',
                'userID' : {'$in' : memberIDs}
            } 

            socket.emit('get-mongo', memberDetailsQuery)
            let thisUser = curOrg?.members?.find((member => member.userID === user.uid))
            setMembership(thisUser)
            setIsOrgAdmin(thisUser?.role == "Admin")
            setOrgName(curOrg?.orgName)
        }

        const responseHandler = (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)
            if(parsed["type"] == 'get-user'){
                if (parsed["data"]) {
                    setMemberDetails(parsed["data"])
                    socket.off('response', responseHandler)
                }
            }
        }

        console.log(curOrg.members);

        socket.on('response', responseHandler)
    }, [curOrg])

    return (<>
        <OrgInvite showModal={inviteMembers} setShowModal={setInviteMembers} socket={socket}/>
        <DeleteItem socket={socket} isOpen={leavingOrg} closeModal={() => setLeavingOrg(false)} handleDelete={handleLeaveOrg} action={"leave_org"}/>
        <div className="relative">
            <div className="w-full grid grid-cols-1 m-4 p-4 max-h-[32rem] overflow-y-auto scrollbar-none">
                <div className={`flex flex-col w-full`}>
                    <div id="picture" className="flex flex-col items-center justify-center text-xs">
                        <div className="">
                            {
                                !imageLink && !uploadInProgress ?
                                    !curOrg?.orgImage ? <Avvvatars value={user.displayName} size={150} /> : <img src={curOrg?.orgImage} className="object-cover rounded-full w-[8rem] h-[8rem]" />
                                    :
                                    uploadInProgress ?
                                        <div className="flex items-center justify-center rounded-full bg-slate-900 w-[9.5rem] h-[9.5rem]"><Loader /></div> :
                                        <img src={imageLink} className="object-cover rounded-full w-[9.5rem] h-[9.5rem]" />
                            }
                        </div>
                        <div className={`${!isEditing ? 'hidden' : ''} flex flex-row items-center justify-center pt-4 gap-6 text-xs pb-6`}>
                            <label className={` ${!isEditing ? "bg-black bg-opacity-20" : "bg-white bg-opacity-10 hover:bg-opacity-30 hover:font-bold"} p-2 font-medium rounded-md text-sm`}>
                                <span>Change</span>
                                <input type="file" className="hidden" onChange={handleFileChange} disabled={!isEditing} />
                            </label>
                            <button className="bg-white bg-opacity-10 hover:bg-opacity-30 hover:font-bold disabled:bg-opacity-20 disabled:bg-black disabled:font-medium p-2 font-medium rounded-md text-sm" disabled={(selectedFile || imageLink) && isEditing ? false : true} onClick={selectedFile ? uploadFile : () => setImageLink("")}>{selectedFile ? "Upload" : "Remove"}</button>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                     <p className={`${!isEditing ? "" : "hidden"} text-white font-bold text-xl pt-4`}>{curOrg ? curOrg.orgName : ''}</p>
                    <p className={`${!isEditing ? "" : "hidden"} text-gray-400 font-medium text-md`}>{cachedAdmin ? 'Admin' : 'Member'}</p>
                    <input className={`${!isEditing ? "hidden" : ""} text-white font-medium p-2 rounded-lg text-xl bg-slate-700 focus:outline-none text-center`} value={orgName} onChange={(e) => setOrgName(e.target.value)} placeholder={"Organization Name"}/>
                    </div>
                    <div className="flex flex-row">
                    <p className={`text-white text-start font-bold text-xl p-3`}>People</p>
                    <button className={`${isOrgAdmin ? '' : 'hidden'}`} onClick={() => setInviteMembers(true)}>+</button>
                    </div>
                    <div className="flex flex-col items-center pt-6 p-3">
                    {
                        curOrg && curOrg.members &&
                        curOrg.members.map((member, index) => {
                            return <OrgMember member={member} isAdmin={cachedAdmin} socket={socket} isEditing={isEditing} orgID={curOrg.orgID} memberDetails={memberDetails.find(item => item.userID === member.userID)} key={index}/>
                        })
                    }
                    </div>
                    <div className="absolute top-0 left-0 m-3 -mt-1" onClick={orgList.length > 1 ? () => setLeavingOrg(true) : noop()}>
                        <Tooltip className="bg-gray-500 text-xs" content={orgList.length > 1 ? "Leave "+orgName : "Sorry, can't leave your default org"}>
                            <ArrowLeftOnRectangleIcon className="w-6 h-6 text-slate-600 hover:text-red-400" />
                        </Tooltip>
                    </div>
                    <div className="absolute left-0 bottom-0 -mb-7 p-3 animate-fadeIn">
                        {
                            isEditing ?
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-orange-400" onClick={locksmith} />
                                    <p className="text-sm text-slate-400">Caution - you may have unsaved changes</p>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <div className="absolute right-0 bottom-0 -mb-7 p-3">
                        {
                            isEditing ? <LockOpenIcon className="h-5 w-5 text-yellow-400" onClick={locksmith} /> : <Tooltip content={`${isOrgAdmin ? "Make Changes" : "Sorry, you're not an Admin"}`} placement='top' className={`${isOrgAdmin ? "bg-gray-500" : "bg-red-400"} text-xs text-white text-center`}><LockClosedIcon className="h-5 w-5 text-yellow-400" onClick={isOrgAdmin ? locksmith : noop} /></Tooltip>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}