import { useState } from 'react';
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { Input } from '../../ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../ui/select';
import { Textarea } from '../../ui/textarea';

export default function BuilderViewer({interviewers}) {

    const [droppedQuestions, setDroppedQuestions] = useState({})

    function handleDrop(e){
        e.preventDefault()
        const data = e.dataTransfer.getData('application/json')
        const { category, question } = JSON.parse(data)

        setDroppedQuestions((prevDropped) => {
            const updatedQuestions = {...prevDropped}
            if (!updatedQuestions[category]){
                updatedQuestions[category] = []
            }
            updatedQuestions[category].push(question)
            return updatedQuestions
        })
    }

    function handleDragOver(e){
        e.preventDefault()
    }

    return (
        <div className="builder-viewer p-4 grid-cols-1 divide-y divide-dashed divide-white/10 h-full">
            <div>
                <h1 className="ml-1 mb-5 uppercase text-xs font-semibold">Interview Details : </h1>
                <div id="interviewDetails" className="grid grid-cols-3 gap-4 mb-4">
                    <Input className="bg-[#071A28] border-1 border-gray-100" placeholder="Interview Name" />
                    <Select>
                        <SelectTrigger className="bg-[#071A28] border-1 border-gray-100">
                            <SelectValue placeholder="Product Stage" />
                        </SelectTrigger>
                        <SelectContent className="bg-[#071A28] border-1 border-gray-100">
                            <SelectItem value="stage1" className="hover:bg-[#0A2A3E]">Ideation</SelectItem>
                            <SelectItem value="stage2" className="hover:bg-[#0A2A3E]">Building</SelectItem>
                            <SelectItem value="stage3" className="hover:bg-[#0A2A3E]">Operations</SelectItem>
                        </SelectContent>
                    </Select>
                    <Select>
                        <SelectTrigger className="bg-[#071A28] border-1 border-gray-100">
                            <SelectValue placeholder="Assigned Interviewer" />
                        </SelectTrigger>
                        <SelectContent className="bg-[#071A28] border-1 border-gray-50">
                            <SelectItem value="Eve" className="hover:bg-[#0A2A3E]">Eve (Default)</SelectItem>
                            {interviewers && interviewers.map((item, index) => (
                                <SelectItem value={item.interviewerID} key={index} className="hover:bg-[#0A2A3E]">
                                    {item.interviewerName}
                                </SelectItem>
                            ))}
                            <SelectItem value="Create New Interviewer" className="hover:bg-[#0A2A3E]">Create New Interviewer</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4">
                    <Textarea className="bg-[#071A28] border-1 border-gray-50 text-sm text-gray-100" placeholder="Interview Guidelines" rows={4} />
                </div>
            </div>
            <div id="questionSet" className="h-full">
                <h1 className="ml-1 my-5 uppercase text-xs font-semibold">Question Set : </h1>
                <div className="h-full">
                    <div id="dropzone" className={`${Object.keys(droppedQuestions).length === 0 ? "flex items-center justify-center" : ""} flex flex-row overflow-x-auto scrollbar scrollbar-none rounded-md w-full h-[65%] border border-dashed border-white/20`} onDrop={handleDrop} onDragOver={handleDragOver}>
                            {Object.keys(droppedQuestions).length === 0 ? (
                                <p className="text-center text-gray-400 hover:text-gray-200 hover:scale-105">Drag and drop a topic to get started</p>
                            ) : (
                            Object.keys(droppedQuestions).map((category, index) => (
                                <> 
                                <div key={index} className="bg-[#071A28] p-2 m-2 flex-1 rounded-md min-w-[300px] max-w-[300px] overflow-y-auto scrollbar scrollbar-none">
                                    <h2 className="text-sm font-semibold mb-2">{category}:</h2>
                                    <ul className="text-xs list-disc list-inside">
                                        {droppedQuestions[category].map((question, index) => (
                                            <div className="bg-white/10 rounded-md p-2 my-2" key={index}>{question}</div>
                                        ))}
                                    </ul>
                                </div>
                                <div className={`${index == Object.keys(droppedQuestions).length - 1 ? "hidden" : ""} flex items-center`}>
                                    <ArrowRightIcon className={`w-5 h-5 `}/>
                                </div>
                                </>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
