import { useEffect, useState, Fragment } from 'react'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/outline'
import Tooltip from "@material-tailwind/react/components/Tooltip"
import DeleteItem from "./DeleteItem";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
  } from '../ui/breadcrumb';

export default function DashboardPath({ socket, itemID, screen, newProfile, newInterview, selected, setShowModal }) {
    const [isOpen, setIsOpen] = useState(false);


    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    function handleLinkClick(){
        setShowModal(true)
    }

    function handleDelete() {
        console.log(itemID)
        const query = {
            itemID: itemID,
            collection: screen === "Profiles" ? "profiles" : screen === "Interviews" ? "interviews" : null
        };

        if (!query.collection) {
            console.error('Invalid screen type:', screen);
            return;
        }

        socket.emit('delete-mongo', query, (response) => {
            if (response.error) {
                console.error('Delete failed:', response.error);
            } else {
                console.log('Delete successful:', response);
                window.location.reload(); // Reload the page on successful delete
            }
        });

        closeModal();
    }

    return (
        <div className='w-full'>
                <div className="flex justify-between align-center">
                    <div className="text-md text-white font-bold mt-1">
                    {
                        newProfile || newInterview ?
                        <p className="pl-4">New {screen.slice(0, -1)}</p>
                        :
                        <Breadcrumb>
                            <BreadcrumbList>
                                {screen && (
                                    <>
                                        <BreadcrumbItem>
                                            <BreadcrumbLink href="#">{screen}</BreadcrumbLink>
                                        </BreadcrumbItem>
                                        <BreadcrumbSeparator />
                                    </>
                                )}
                                {selected && (
                                    <BreadcrumbItem>
                                        <BreadcrumbPage>{selected}</BreadcrumbPage>
                                    </BreadcrumbItem>
                                )}
                            </BreadcrumbList>
                        </Breadcrumb>
                    }
                    </div>
                    <div className={`flex flex-wrap p-1 mr-5 gap-x-5`}>
                        <div className={`${screen === "Interviews" ? "" : "hidden"}`}>
                            <Tooltip content="Share Invites" className="bg-slate-500 text-xs ">
                            <div>
                                <ArrowUpOnSquareIcon className="h-5 w-5 text-gray-300 hover:text-gray-300" onClick={handleLinkClick}/>
                            </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip content={"Delete "+ screen.substring(0, screen.length-1) } className="bg-slate-500 text-xs">
                            <div>
                                <TrashIcon className="h-5 w-5 text-gray-300 hover:text-gray-300" onClick={openModal}/>
                            </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            <DeleteItem socket={socket} isOpen={isOpen} closeModal={closeModal} handleDelete={handleDelete} action={screen.toLowerCase()}/>
        </div>
    )
}