import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { useEffect } from "react";
import InterviewTopic from "./InterviewTopic";

export default function BuilderSidebar(props) {

    const topicList = [
        {
            topic: "Introduction",
            questions: ["Ask about their job & responsibilities", "Ask what industry they work in", "Intro 1"]
        },
        {
            topic: "Demographics",
            questions: ["Demographics 1", "Demographics 2", "Demographics 3"]
        },
        {
            topic: "Current Solutions",
            questions: ["Current 1", "Current 2", "Current 3"]
        },
        {
            topic: "Pain Points & Challenges",
            questions: ["Pain 1", "Pain 2", "Pain 1993"]
        },
        {
            topic: "Desired Solutions",
            questions: ["Solution 1", "Solution 2", "Solution 3"]
        },
        {
            topic: "Product Validation",
            questions: ["Validate 1", "Validate 2", "Validate 3"]
        },
        {
            topic: "Closing",
            questions: ["Close 1", "Close 2", "Close 3"]
        },
    ]

  return (
    <div className="overflow-y-auto scrollbar scrollbar-none h-full">
      <div className="flex grid grid-cols-1">
        <div>
          <ul className="grid grid-cols-1 w-full">
          {
            topicList.map((topicItem, index) => {
                return (
                    <>
                        <li className="flex items-center border-b-2 border-white/10 p-2" key={index}>
                            <InterviewTopic topic={topicItem.topic} questions={topicItem.questions} key={index}/>
                        </li>
                    </>
                )
            })
          }
          </ul>
        </div>
      </div>
    </div>
  );
}