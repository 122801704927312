import { useState, useEffect } from "react";

export default function TabView(props) {
  const tabsData = [
    {
      id: 1,
      name: "Configure Interview",
      title: "Personalize Your Interview Setup",
      imageURL: "https://mypeerai.s3.amazonaws.com/interview-builder.png",
      description:
        "All you need is the product you're working on and a research objective. Build custom interviewers or let Eve from Entropi represent you in the interview.",
    },
    {
      id: 2,
      name: "Invite Customers",
      title: "Send Unique, Trackable Invites",
      imageURL: "https://mypeerai.s3.amazonaws.com/invite.png",
      description:
        "Mass provision invites and export them to your preferred bulk mail service. Once interviews are completed, dive into the customer's profile and their conversations, or get a quick summary of what they think.",
    },
    {
      id: 3,
      name: "Long Term Insights",
      title: "Simulate Interviews with Customer Profiles",
      imageURL: "https://mypeerai.s3.amazonaws.com/persona.png",
      description:
        "Run simulated interviews with batches of customer profiles at once, and get actionable suggestions towards building a better experience. Customer profiles improve as they undergo interviews, either simulated or via an invite. ",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabsData[0].id);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [fadeValue, setFadeValue] = useState(1);

  useEffect(() => {
    const activeTabData = tabsData.find(tab => tab.id === activeTab);
    if (activeTabData && activeTabData.imageURL) {
      const img = new Image();
      img.src = activeTabData.imageURL;
      img.onload = () => setIsImageLoaded(true);
    } else {
      setIsImageLoaded(true);
    }
  }, [activeTab]);


  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8 mx-10">
      <div className="flex flex-col space-y-4">
        {tabsData.map((tab) => (
          <button
            key={tab.id}
            className={`text-left font-medium p-4 rounded-lg transition-colors duration-500 ${
              activeTab === tab.id
                ? `bg-gradient-to-tl from-purple-900 to-fuchsia-950 text-white opacity-${fadeValue * 100}`
                : "bg-gray-700 text-gray-400 hover:bg-gray-600 hover:text-white"
            }`}
            onClick={() => {
              setIsImageLoaded(false);
              setActiveTab(tab.id);
              setFadeValue(1);
            }}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="col-span-2 p-10 bg-gray-700 rounded-lg transition-opacity duration-500" style={{ opacity: isImageLoaded ? 1 : 0 }}>
        {tabsData
          .filter((tab) => tab.id === activeTab)
          .map((tab) => (
            <div key={tab.id}>
              <h1 className="text-3xl font-bold mb-4 text-center">{tab.title}</h1>
              {tab.imageURL && (
                <img
                  src={tab.imageURL}
                  alt={tab.name}
                  className="w-full h-auto mb-4 rounded-lg"
                />
              )}
              <p className="text-md font-medium text-gray-300">{tab.description}</p>
              {tab.id === 2 && (
                <div className="mt-6 flex items-center space-x-4">
                  <p className="font-medium text-xl">Want to try it out?</p>
                  <button
                    className="bg-purple-600 text-white font-semibold text-lg py-2 px-4 rounded-md transition-colors duration-300 hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50"
                    onClick={props.tryNow}
                  >
                    Try Now
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
