import { useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

export default function InterviewTopic(props){
    const [isExpanded, setIsExpanded] = useState(false)

    function onTopicClick(){
        setIsExpanded(!isExpanded)
    }

    function handleDragStart(e, question){
        e.dataTransfer.setData('application/json', JSON.stringify({category: props.topic, question: question}))
    }

    return(
        <>
        <div className={`flex flex-col grid grid-rows-[25%_75%] w-full p-1 ${isExpanded ? "gap-1" : ""}`}>
            <div id="topic" onClick={onTopicClick} className="flex items-center h-fit select-none">
                <div className="grid grid-cols-2 w-full">
                    <div id="topic-title">
                        <p className="font-medium text-sm">{props.topic}</p>
                    </div>
                    <div id="dropdown-icon" className="flex items-center justify-end">
                        {
                            isExpanded ? <ChevronUpIcon className="w-5 h-5"/> : <ChevronDownIcon className="w-5 h-5"/>
                        }
                    </div>
                </div>
            </div>
            <div id="questions" className={`${isExpanded ? "visible" : "hidden"} grid grid-cols-1 gap-y-2 select-none mb-8`} >
            {
                props.questions.map((question, index) => {
                    return <p className="bg-[#04111A] rounded-md border border-dashed border-[#8C8C8C] p-2.5 text-left text-xs" key={index} draggable={true} onDragStart={(e) => handleDragStart(e, question)}>{question}</p>
                })
            }
            </div>
        </div>
        </>
    )
}