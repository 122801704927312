import { useEffect, useState, Fragment } from 'react'
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import { HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/24/outline";
import ThumbsDownSolid from "@heroicons/react/24/solid/HandThumbDownIcon";
import ThumbsUpSolid from "@heroicons/react/24/solid/HandThumbUpIcon";
import Avvvatars from 'avvvatars-react'
const uuid = require('uuid')


export default function ProfileCard(props) {
    const socket = props.socket
    const interviewID = props.interviewID
    const profile = props.selectedProfile
    const auth = getAuth();
    const user = auth.currentUser
    const productID = useParams()["productID"];
    const [transcript, setTranscript] = useState([])
    const [isOpen, setIsOpen] = useState(false);

    const ChatMessage = ({ message, interviewerName, customerName }) => {
        const [liked, setLiked] = useState(false)
        const [disliked, setDisliked] = useState(false)
        const [feedbackID, setFeedbackID] = useState('')

        function handleLike() {
            // If the message is not liked or disliked
            if (!liked && !disliked) {
                let feedbackUUID = uuid.v4().toString()
                setFeedbackID(feedbackUUID)
                var addQuery = {
                    'collection' : 'chat_feedback',
                    'feedbackID': feedbackUUID,
                    'userID' : user.uid,
                    'message' : message,
                    'type': 'positive'
                }
                socket.emit('add-mongo', addQuery)
                setDisliked(false)
                setLiked(true)

            }
            // If the message is not liked, but is currently disliked
            else if (!liked && disliked){
                var updateQuery = {
                    'collection' : 'chat_feedback',
                    'query' : {
                        'feedbackID' : feedbackID
                    },
                    'update' : {
                        'type' : 'positive'
                    }
                }
                socket.emit('update-mongo', updateQuery)
                setDisliked(false)
                setLiked(true)
            }
            // If its already liked and not disliked, remove the like from db
            else {
                var delQuery = {
                    'collection' : 'chat_feedback',
                    'feedbackID' : feedbackID
                }
                socket.emit('delete-mongo', delQuery)
                setLiked(false)
            }
        }

        function handleDislike() {
            // If the message is not disliked
            if (!disliked) {
                let feedbackUUID = uuid.v4().toString()
                setFeedbackID(feedbackUUID)
                var addQuery = {
                    'collection' : 'chat_feedback',
                    'feedbackID': feedbackUUID,
                    'userID' : user.uid,
                    'message' : message,
                    'type': 'negative'
                }
                socket.emit('add-mongo', addQuery)
                
                setLiked(false)
                setDisliked(true)

            }
            // If the message is not liked, but is currently disliked
            else if (!liked && disliked){
                var updateQuery = {
                    'collection' : 'chat_feedback',
                    'query' : {
                        'feedbackID' : feedbackID
                    },
                    'update' : {
                        'type' : 'negative'
                    }
                }
                socket.emit('update-mongo', updateQuery)
                setLiked(false)
                setDisliked(true)
            }
            // If its already disliked, remove the dislike from db
            else {
                var delQuery = {
                    'collection' : 'chat_feedback',
                    'feedbackID' : feedbackID
                }
                socket.emit('delete-mongo', delQuery)
                setDisliked(false)
            }
        }

        useEffect(() => {
            // Get all feedback for this user.
            let feedbackQuery = {
                'collection' : 'chat_feedback',
                'userID' : user.uid
            }

            socket.emit('get-mongo', feedbackQuery)

            socket.on('response', (data) => {
                data = JSON.stringify(data)
                var parsed = JSON.parse(data)

                if (parsed['type'] === 'get-feedback'){
                    let feedbackData = parsed['data']
                    // Try finding this messages text within the list of messages.
                    let result = feedbackData.find(obj => obj.message.content === message.content);
                    // If a result is found look at its type and set variables accordingly
                    if (result){
                        let type = result.type
                        if (type === "positive"){
                            setLiked(true)
                        }
                        else if (type === "negative"){
                            setDisliked(true)
                        }
                    }
                }
            })
        }, [profile])

        return (
            <div className={`flex text-white ${message.role === "assistant" ? "justify-start" : "justify-end"} gap-x-5 p-4 m-4 items-center`}>
                <div className="grid grid-cols-1">
                    <div className={`text-xs font-medium m-1 ${message.role === "assistant" ? "text-left" : "text-right"}`}>
                        {message.role === "assistant" ? interviewerName : customerName}
                    </div>
                    <div
                        className={"text-left bg-slate-700 bg-opacity-80 backdropblur-xl shadow-sm rounded-lg p-4 max-w-lg"}
                    >
                        <p className='text-sm text-gray-300 font-medium'>{message.content}</p>
                    </div>
                    <div className={`${message.role === "assistant" ? '' : 'hidden'} p-3 flex flex-row gap-3`}>
                        {liked ? <ThumbsUpSolid className="w-4 h-4 text-green-500" onClick={handleLike} /> : <HandThumbUpIcon className="w-4 h-4 text-gray-300" onClick={handleLike} />}
                        {disliked ? <ThumbsDownSolid className="w-4 h-4 text-red-500" onClick={handleDislike}/> : <HandThumbDownIcon className="w-4 h-4 text-gray-300" onClick={handleDislike}/>}
                    </div>
                </div>
            </div>
        )
    }

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (profile && profile.history) {
            let linkQuery = {
                'collection': 'links',
                'interviewID': interviewID
            }
            socket.emit('get-mongo', linkQuery)
            socket.on('response', (data) => {
                data = JSON.stringify(data)
                var parsed = JSON.parse(data)
                if (parsed['type'] === 'get-links') {
                    let linkList = parsed['data']
                    let historyIDs = []
                    profile.history.map((hist) => {
                        historyIDs.push(hist.id)
                    })
                    let thread = linkList.find(linkObj => {
                        return profile.history.some(historyObj => historyObj.id === linkObj.linkID);
                    })
                    if (thread) {
                        thread = thread.conversation;
                        setTranscript(thread)
                    }
                }
            })
        }
    }, [JSON.stringify(profile)])



    return (<> {
        <div>
            <div className={`${JSON.stringify(profile) != "{}" ? '' : 'hidden'} z-0 grid grid-cols-1 bg-black bg-opacity-20 rounded-xl p-6 shadow-xl mx-24 relative`}>
                <p className="text-white text-[0.6rem] hover:text-slate-200 hover:bg-slate-700 w-fit h-fit m-3 absolute top-0 right-0 rounded-md p-1 px-2 bg-slate-800"
                    onClick={() => window.location = ('/' + productID + '/profiles?' + profile.profileID)}>Visit Profile</p>

                <div className="flex profiles-center justify-center">
                    <Avvvatars size={60} value={profile ? profile.firstName + " " + profile.lastName : ''} />
                </div>
                <p className="text-center pt-3 text-white font-semibold text-opacity-90">{profile ? profile.firstName + " " + profile.lastName : ''}</p>
                <p className="text-center text-white font-medium text-opacity-70 text-xs">{profile && profile.details ? profile.details.occupation.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ') : ''}</p>
                <p className="text-start h-24 line-clamp-4 p-2 pt-3 text-white text-opacity-90 text-sm">{profile && profile.details ? profile.details.productAlignment : ''}</p>
                <div className="pt-5">
                    <p onClick={openModal} className={`${transcript && transcript.length > 0 ? '' : 'hidden'} text-white text-[0.6rem] hover:text-slate-200 hover:bg-slate-700 w-fit h-fit m-3 absolute bottom-0 left-0 rounded-md p-1 px-2 bg-slate-800`}>Transcript</p>
                </div>   
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
                        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
                        <div className="relative inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
                            <p className="text-white text-xs hover:text-slate-200 hover:bg-slate-700 w-fit h-fit m-3 absolute top-0 right-0 rounded-md p-1 px-2 bg-slate-800"
                                onClick={closeModal}>Close</p>
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white text-opacity-80 text-center">
                                {profile ? profile.firstName + "'s " + "Response" : ''}
                            </Dialog.Title>
                            <div className="mt-3 overflow-auto max-h-[35rem]">
                                {
                                    transcript ? transcript.map((message, index) => {
                                        return (
                                            <ChatMessage message={message} interviewerName={"Interviewer"} customerName={profile ? profile.firstName + " " + profile.lastName : ''} key={index}/>
                                        )
                                    })
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    }
    </>
    )
}