import React, { useEffect, useState, useRef } from "react";
import { Gradient } from "../../Gradient";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TabView from "./components/TabView";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { v4 as uuidv4 } from 'uuid';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../components/ui/accordian";
import { motion } from "framer-motion";

export default function Landing({ socket }) {
    const gradient2 = new Gradient();
    const [demoSubmitted, setDemoSubmitted] = useState(false);
    const [isTryNowModalOpen, setIsTryNowModalOpen] = useState(false);
    const [isViewDemoModalOpen, setIsViewDemoModalOpen] = useState(false);

    useEffect(() => {
        gradient2.initGradient("#gradient-canvas2");
    }, []);

    const earlyAccess = () => {
        const emailField = document.getElementById("email").value;
        if (emailField && emailField.includes("@")) {
            const data = { email: emailField, link: document.URL };
            socket.emit("sign-up", data);
            document.getElementById("email").value = "";
        }
    };

    const handleTryNow = () => {
        setIsTryNowModalOpen(true);
    };

    const handleViewDemo = () => {
        setIsViewDemoModalOpen(true);
    };

    const handleDemoSubmit = () => {
        const firstNameField = document.getElementById('firstName');
        const lastNameField = document.getElementById('lastName');
        const emailField = document.getElementById('email');

        const firstName = firstNameField.value.trim();
        const lastName = lastNameField.value.trim();
        const email = emailField.value.trim();

        if (firstName && lastName && email) {
            const linkID = uuidv4();
            const linkURL = `https://chat.entropi.app/${linkID}`;
            const addLink = {
                collection: 'links',
                userInfo: {},
                interviewID: "1def4305-f5b1-4096-802d-540e41a0bee2",
                linkID,
                status: "unopened",
                productID: "056f8a4b-5c04-4276-be61-4f4d35955e0b",
                timestamp: new Date(),
            };
            socket.emit("add-mongo", addLink);

            const mailData = { link: linkURL, firstName, email };
            socket.emit('landing-demo', mailData);

            setDemoSubmitted(true);
            firstNameField.value = "";
            lastNameField.value = "";
            emailField.value = "";

            setTimeout(() => setDemoSubmitted(false), 1500);
        }
    };

    const closeModal = () => {
        setIsTryNowModalOpen(false);
        setIsViewDemoModalOpen(false);
    };

    return (
        <div className="overflow-x-hidden scrollbar-thumb-gray-800 scrollbar-thin scrollbar-thumb-rounded scrollbar-track-black/4 scroll-smooth" id="root">
            <Header />
            <div className="w-full h-screen grid grid-cols-1">
                <div className="h-screen flex items-center justify-center">
                    <div className="w-full h-screen bg-opacity-40">
                        <div className="relative w-full h-full backdrop-blur-xl items-center justify-center">
                            <div className="absolute w-full h-full flex flex-col items-center justify-center z-10 mx-auto">
                                <motion.p 
                                    className="text-violet-100 font-medium text-4xl sm:text-6xl text-start md:text-center md:leading-tight md:text-[9vh] md:w-[80%] pb-2 text-white text-shadow-xl px-4 w-full"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Extend the value of user feedback, <span className="text-purple-500">infinitely.</span>
                                </motion.p>
                                <motion.p 
                                    className="pt-2 text-gray-200 text-sm sm:text-lg md:leading-tight md:text-4xl pl-4 md:pl-0 text-start md:text-center m-4 w-full md:w-[60%]"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Capture qualitative insight with AI moderated research to drive your product from 0 → 1.
                                </motion.p>
                                <div className="flex w-full justify-center items-center gap-x-3 mt-3 md:mt-8 pt-3">
                                    <button className="bg-white text-black font-medium text-lg sm:text-xl py-3 sm:py-4 px-6 sm:px-8 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-50" onClick={handleTryNow}>
                                        Try it Out
                                    </button>
                                    <button id="submit" type="submit" onClick={handleViewDemo} className="bg-transparent border border-white text-white font-medium text-lg sm:text-xl py-3 sm:py-4 px-6 sm:px-8 rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-50 flex items-center">
                                        View Demo →
                                    </button>
                                </div>
                            </div>
                        </div>
                        <motion.div 
                            className="flex flex-col items-center justify-center mb-[5rem] mx-auto bg-white/5 backdrop-blur-lg rounded-lg p-6 sm:p-8 shadow-lg max-w-5xl"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <p className="text-white font-semibold text-sm sm:text-md md:text-lg mb-8 text-center">Trusted by the best product managers, researchers, and builders at </p>
                            <div className="flex flex-wrap justify-center items-center gap-6 sm:gap-10 opacity-60">
                                <img 
                                    src="https://mypeerai.s3.amazonaws.com/Logos/UVA.png" 
                                    alt="Company 1" 
                                    className="h-16 sm:h-16 md:h-16"
                                />
                                <img 
                                    src="https://mypeerai.s3.amazonaws.com/Logos/NG.png" 
                                    alt="Company 2" 
                                    className="h-12 sm:h-12 md:h-12"
                                />
                                <img 
                                    src="https://mypeerai.s3.amazonaws.com/Amazon+Logo.png" 
                                    alt="Company 3" 
                                    className="h-10 mt-2 sm:h-10 md:h-10"
                                />
                                <img 
                                    src="https://mypeerai.s3.amazonaws.com/Logos/GW.png" 
                                    alt="Company 4" 
                                    className="h-12 sm:h-12 md:h-12"
                                />
                                <img 
                                    src="https://mypeerai.s3.amazonaws.com/Logos/USC.png" 
                                    alt="Company 5" 
                                    className="h-12 sm:h-12 md:h-12"
                                />
                            </div>
                        </motion.div>
                        <div id="product" className="align-items-center">
                            <div className="flex flex-col items-center justify-center m-8 mx-auto text-center md:px-10">
                                <motion.p 
                                    className="text-violet-100 relative font-medium text-4xl sm:text-5xl m-2 p-2 md:m-4 md:p-4 md:pt-[4rem] md:text-[8vh] md:w-[75%] md:leading-tight"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Elevate your product research with AI-driven insights.
                                </motion.p>
                                <motion.p 
                                    className="max-w-5xl text-white mt-[1rem] text-opacity-80 relative font-normal text-md sm:text-lg md:text-3xl md:leading-snug mb-2 pb-2 mx-4 px-4"
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Configure interviews, invite customers with trackable links, and harness long-term insights emulation to transform your product strategy at scale.
                                </motion.p>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <TabView tryNow={handleTryNow} />
                            </motion.div>
                            <div className="">
                                <div className="mt-5 mb-[15rem] py-10 mx-4 p-2 md:p-8 md:pt-[10rem]">
                                    <div className="space-y-4 p-2 m-2 md:m-8 md:p-8 rounded-lg md:pt-0">
                                        <motion.h2 
                                            className="text-2xl font-semibold mb-8 text-violet-100 md:text-6xl"
                                            initial={{ opacity: 0, y: 50 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            FAQs
                                        </motion.h2>
                                        <Accordion className="text-start" type="single" collapsible>
                                            <AccordionItem value="item-1" className="border-gray-800">
                                                <AccordionTrigger className="text-white">How much does Entropi cost?</AccordionTrigger>
                                                <AccordionContent className="text-gray-300">
                                                    <p>Entropi is currently free to access for a limited time as beta access is rolled out to early users. This allows you to explore all features and provide feedback to help us improve the platform. Once the beta phase is over, we will introduce various pricing tiers to suit different business needs and scales. These tiers will range from a basic free plan for small startups to more comprehensive plans for larger enterprises, ensuring that every business can find a plan that fits their needs and budget.</p>
                                                </AccordionContent>
                                            </AccordionItem>
                                            <AccordionItem value="item-2" className="border-gray-800">
                                                <AccordionTrigger className="text-white text-start">How do customer interviews work through Entropi?</AccordionTrigger>
                                                <AccordionContent className="text-gray-300">
                                                    <p>Entropi automates your product research process by using custom interviewer agents. These agents conduct customer interviews on your behalf, asking tailored questions to gather deep qualitative insights. The interviews are then synthesized into comprehensive reports, providing you with actionable data to drive your product development. You can customize the interview process to match your specific needs, including setting the tone of the interview, the type of questions asked, and the duration of each session. This ensures that the data collected is highly relevant and useful for your product strategy.</p>
                                                </AccordionContent>
                                            </AccordionItem>
                                            <AccordionItem value="item-3" className="border-gray-800">
                                                <AccordionTrigger className="text-white">What are simulations?</AccordionTrigger>
                                                <AccordionContent className="text-gray-300">
                                                    <p>Simulations are a key feature of Entropi's long-term framework, designed to exponentially increase the scale at which product teams can validate their ideas and hypotheses. By running simulated interviews with virtual customer profiles, teams can quickly gather feedback and iterate on their concepts. This feature is set to launch in early 2024 and aims to revolutionize the way product research is conducted. Simulations allow you to test multiple scenarios and gather data on how different customer segments might respond to your product, providing a more comprehensive understanding of market needs and potential challenges.</p>
                                                </AccordionContent>
                                            </AccordionItem>
                                            <AccordionItem value="item-4" className="border-gray-800">
                                                <AccordionTrigger className="text-white">Can I customize the interview questions?</AccordionTrigger>
                                                <AccordionContent className="text-gray-300">
                                                    <p>Yes, Entropi allows you to fully customize the interview questions to align with your specific research objectives. You can create your own set of questions or use our pre-built templates to get started quickly. This flexibility ensures that you gather the most relevant insights for your product development. Additionally, you can adjust the interview flow based on the responses received, making the process more dynamic and responsive to the interviewee's input. This level of customization helps in obtaining more accurate and detailed insights.</p></AccordionContent>
                                            </AccordionItem>
                                            <AccordionItem value="item-5" className="border-gray-800">
                                                <AccordionTrigger className="text-white">How secure is my data with Entropi?</AccordionTrigger>
                                                <AccordionContent className="text-gray-300">
                                                    <p>We take data security very seriously at Entropi. All data is encrypted both in transit and at rest, and we adhere to industry best practices to ensure your information is protected. Additionally, we regularly conduct security audits and updates to maintain the highest level of security for our users. Our platform is compliant with major data protection regulations such as GDPR and CCPA, ensuring that your data is handled with the utmost care and confidentiality. We also offer features like two-factor authentication and role-based access control to further enhance the security of your data.</p>
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </div>
                                <div id='contact' className="relative w-full h-full pt-[15rem] grid-cols-1 gap-y-5">
                                    <canvas
                                        id="gradient-canvas2"
                                        data-transition-in
                                        className="absolute md:top-0 md:left-0 w-full h-full rounded-t-full  -z-10"
                                    />
                                    <motion.div 
                                        className="relative flex flex-col items-center justify-center max-w-5xl mx-auto p-2 py-10 my-10 md:p-8 bg-white bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-lg z-10"
                                        initial={{ opacity: 0, y: 50 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <h2 
                                            className="text-2xl sm:text-3xl font-bold mb-4 text-violet-100 md:text-5xl text-center"
                                        >
                                            Interested, but not sure where to start? 
                                        </h2>
                                        <p className="text-gray-300 text-md sm:text-lg mb-6 text-center max-w-3xl md:text-xl">
                                            Learn how Entropi can enhance your product research and development. Schedule a call to see how Entropi can be integrated into your workflow.
                                        </p>
                                        <div className="w-full flex justify-center">
                                            <a 
                                                href="https://calendly.com/admin-f-0/30min" 
                                                className="border-2 border-purple-600 text-white hover:bg-purple-600 hover:text-white text-md sm:text-lg font-bold py-2 sm:py-3 px-4 sm:px-6 rounded-lg focus:outline-none focus:shadow-outline transition-colors duration-300 text-center flex items-center" 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                            >
                                                Set Up a Call →
                                            </a>
                                        </div>
                                    </motion.div>
                                    <div className="h-[150px] hidden md:visible"></div>
                                    <Footer className="relative w-full h-auto z-100 pt-[3rem]" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isTryNowModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={closeModal}>
                    <div className="bg-slate-900/90 rounded-xl m-4 bg-gradient-to-r from-purple-900/70 to-indigo-500/70 w-11/12 max-w-3xl" onClick={(e) => e.stopPropagation()}>
                        <div className="flex items-start align-center rounded-t-xl gap-1 p-4">
                            <div className="flex space-x-1 mt-1">
                                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                                <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                            </div>
                            <div className="bg-white/50 rounded-md px-4 mx-2">
                                <span className="text-sm text-white font-semibold">Entropi Demo</span>
                            </div>
                        </div>
                        <div className="rounded-xl mx-4 mb-4 p-6 pb-8 bg-gradient-to-r from-purple-900/90 to-indigo-500/90 shadow-lg">
                            <div className="flex align-center sm:align-start mx-auto">
                                <h3 className="text-2xl sm:text-3xl text-violet-100 font-bold pb-2">Try Entropi</h3>
                            </div>
                            <p className="text-gray-300 text-md sm:text-lg mt-4 mb-8">See how AI interviewers are able to scale your customer interviews first hand by going through a quick interview.</p>
                            <div id="demoForm" className="space-y-4 mx-auto items-start">
                                <div className="flex flex-wrap gap-4">
                                    <div className="px-2 mb-4 w-full sm:w-1/2">
                                        <label htmlFor="firstName" className="block text-gray-300 text-sm font-bold mb-2">First Name:</label>
                                        <input type="text" id="firstName" name="firstName" className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="First Name" required />
                                    </div>
                                    <div className="px-2 mb-4 w-full sm:w-1/2">
                                        <label htmlFor="lastName" className="block text-gray-300 text-sm font-bold mb-2">Last Name:</label>
                                        <input type="text" id="lastName" name="lastName" className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Last Name" required />
                                    </div>
                                    <div className="px-2 mb-4 w-full">
                                        <label htmlFor="email" className="block text-gray-300 text-sm font-bold mb-2">Email:</label>
                                        <input type="email" id="email" name="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Email" required />
                                    </div>
                                </div>
                                <div className="flex items-start px-2">
                                    <button className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-300" type="submit" onClick={handleDemoSubmit}>
                                        {!demoSubmitted ? "Submit" : <CheckCircleIcon className="h-5 w-5 text-green-600" />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isViewDemoModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={closeModal}>
                    <div className="bg-slate-900/90 rounded-xl m-4 bg-gradient-to-r from-purple-900/70 to-indigo-500/70 w-11/12 max-w-3xl" onClick={(e) => e.stopPropagation()}>
                        <div className="flex items-start align-center rounded-t-xl gap-1 p-4">
                            <div className="flex space-x-1 mt-1">
                                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                                <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                            </div>
                            <div className="bg-white/50 rounded-md px-4 mx-2">
                                <span className="text-sm text-white font-semibold">View Our Demo</span>
                            </div>
                        </div>
                        <div className="rounded-xl mx-4 mb-4 p-6 pb-8 bg-gradient-to-r from-purple-900/90 to-indigo-500/90 shadow-lg">
                            <video className="object-cover rounded-lg w-full h-96" autoPlay loop muted controls>
                                <source src="https://mypeerai.s3.amazonaws.com/Entropi+Initial+Demo.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
