import DashboardHeader from "../dashboard/DashboardHeader"
import DashboardPath from "../dashboard/DashboardPath";
import DashboardSidebar from "../dashboard/DashboardSidebar";
import NoItems from "../dashboard/NoItems";
import InterviewViewer from "../interviews/InterviewViewer";
import ProfileViewer from '../profiles/ProfileViewer';
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
  } from "../ui/resizeable"

export default function InteractiveBox(props) {
    const socket = props.socket;

    return (
        <div className="w-full h-[100vh] mx-[2.5px] bg-cyan-950/50 mt-1">
            {
                (props.screen == 'Profiles' && props.profiles.length === 0) || (props.screen == 'Interviews' && props.interviews.length === 0) ? 
                <NoItems 
                    text={props.screen == 'Profiles' ? "Set up an interview, invite respondents, and check back later" : "Create an interview to get started"} 
                    link={props.screen == 'Profiles' ? window.location.toString().substring(0, window.location.toString().lastIndexOf("profiles")) + 'interviews' : null} 
                    linkText={props.screen == 'Profiles' ? "Interviews" : "New Interview"} 
                    toggle={props.setOnClick} 
                    className="items-center mx-auto my-10"
                />
                :
                <ResizablePanelGroup direction="vertical" className="rounded-lg border">
                    <ResizablePanel defaultSize={7.5}>
                        <ResizablePanelGroup direction="horizontal">
                            <ResizablePanel maxSize={20}>
                                <div className="flex h-full w-full items-start py-2 px-4">
                                    <DashboardHeader socket={socket}/>
                                </div>
                            </ResizablePanel>
                            <ResizableHandle />
                            <ResizablePanel>
                                <div className="flex h-full w-full items-center justify-center p-6">
                                    {
                                        props.screen == 'Profiles' ?
                                            <DashboardPath screen={props.screen} selected={props.selected} name={props.name} newProfile={props.newProfile} socket={props.socket} itemID={props.itemID}/>
                                        :
                                            <DashboardPath screen={props.screen} selected={props.selected} name={props.name} newInterview={props.newInterview} socket={props.socket} itemID={props.curItem.interviewID} setShowModal={props.setShowModal}/>
                                    }
                                </div>
                            </ResizablePanel>
                            <ResizableHandle />
                        </ResizablePanelGroup>
                    </ResizablePanel>
                    <ResizableHandle disabled />
                    <ResizablePanel>
                        <ResizablePanelGroup direction="horizontal">
                            <ResizablePanel maxSize={20}>
                                {
                                    props.screen == 'Profiles' ?
                                    <DashboardSidebar list={props.profiles} setCurItem={props.setCurItem} setOnClick={props.setOnClick} curItem={props.curItem} screen="profile"/>
                                    :
                                    <DashboardSidebar list={props.interviews} setCurItem={props.setCurItem} setOnClick={props.setOnClick} curItem={props.curItem} screen="interview"/>
                                }
                            </ResizablePanel>
                            <ResizableHandle withHandle />
                            <ResizablePanel defaultSize={75} className="overflow-y-auto">
                                {
                                    props.screen == 'Profiles' ?
                                        <ProfileViewer socket={props.socket} curItem={props.curItem} />
                                    :
                                    <>
                                        <InterviewViewer socket={props.socket} curItem={props.curItem} />
                                    </>
                                }
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            }
        </div>
    )
}