export default function NoItems(props){
    return(
        <>
            <div className={`bg-slate-800 bg-opacity-80 rounded-md ${props.className ? props.className : "mt-[35vh]"} max-w-[40%] p-6`}>
                <p className="text-center text-white text-opacity-90 text-lg">{props.text}</p>
                <div className="flex items-center justify-center pt-4"> 
                {
                    props.link ? 
                    <a href={props.link} className="text-center text-white text-opacity-90 text-md"> <span className="rounded-md bg-black bg-opacity-30 hover:bg-opacity-60 p-2">{props.linkText} →</span></a>
                    :
                    <button onClick={() => props.toggle(true)} className="text-center text-white text-opacity-90 text-md"> <span className="rounded-md bg-black bg-opacity-30 hover:bg-opacity-60 p-2">{props.linkText} →</span></button>
                }
                </div>
            </div>    
        </>
    )
}