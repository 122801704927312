import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons"

import { cn } from "../../../lib/utils"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar"
import { Button } from "../../../components/ui/Button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../../components/ui/command"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import { Input } from "../../../components/ui/input"
import { Label } from "../../../components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select"

const groups = [
  {
    label: "Orgs",
    teams: [
      {
        label: "Acme Inc.",
        value: "acme-inc",
      },
      {
        label: "Monsters Inc.",
        value: "monsters",
      },
    ],
  },
]



type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

interface Org {
  _id: string;
  orgID: string;
  orgName: string;
  orgImage: string;
  members: Array<{
    userID: string;
    role: string;
    email?: string;
  }>;
  pull: {
    members: {
      email?: string;
      userID?: string;
    };
  };
}

interface TeamSwitcherProps extends PopoverTriggerProps {
  orgs: Org[];
  curOrg: Org;
  setCurOrg: React.Dispatch<React.SetStateAction<Org>>;
  isDropdownDisabled?: boolean
}

export default function TeamSwitcher({ className, orgs, curOrg, setCurOrg, isDropdownDisabled }: TeamSwitcherProps & { orgs: typeof JSON }) {
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const org_list = [
    {
      label: "Orgs",
      teams: orgs.map(org => ({
        label: org.orgName,
        value: org.orgID,
        image: org.orgImage,
      })),
    },
  ];
  const [showNewTeamDialog, setShowNewTeamDialog] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(curOrg.orgName)

  function handleOrgChange(name: string) {
    const selectedOrg = orgs.find(org => org.orgName === name) || {} as Org;
    setCurOrg(selectedOrg);
    sessionStorage.setItem('curOrg', JSON.stringify(selectedOrg));
    if (isDropdownDisabled){
      navigate("/products")
    }
  }

  return (
    <Dialog open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("w-[200px] justify-between", className)}
            //disabled={isDropdownDisabled}
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={`${curOrg.orgImage}`}
                alt={selectedTeam }
                className="grayscale"
              />
              <AvatarFallback>SC</AvatarFallback>
            </Avatar>
            {curOrg.orgName}
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0 dark:bg-slate-800 border-black">
        <Command>
            <CommandList>
              <CommandInput placeholder="Search org..." />
              <CommandEmpty>No team found.</CommandEmpty>
              {org_list.map((group) => (
                <CommandGroup key={group.label} heading={group.label}>
                  {group.teams.map((team) => (
                    <CommandItem
                      key={team.value} // Changed to team.value for uniqueness
                      onSelect={() => {
                        setSelectedTeam(team.label) // Ensure this matches the structure
                        handleOrgChange(team.label)
                        setOpen(false)
                      }}
                      className="text-sm hover:bg-slate-700"
                    >
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          src={`${team.image}`}// Use team.label for the image src
                          alt={team.label}
                          className="grayscale"
                        />
                        <AvatarFallback>SC</AvatarFallback>
                      </Avatar>
                      {team.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedTeam === team.label // Check against team.label
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
            </CommandList>
            <CommandSeparator className='bg-white/10' />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false)
                      setShowNewTeamDialog(true)
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    Create Team
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create team</DialogTitle>
          <DialogDescription>
            Add a new team to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="space-y-4 py-2 pb-4">
            <div className="space-y-2">
              <Label htmlFor="name">Team name</Label>
              <Input id="name" placeholder="Acme Inc." />
            </div>
            <div className="space-y-2">
              <Label htmlFor="plan">Subscription plan</Label>
              <Select>
                <SelectTrigger>
                  <SelectValue placeholder="Select a plan" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="free">
                    <span className="font-medium">Free</span> -{" "}
                    <span className="text-muted-foreground">
                      Trial for two weeks
                    </span>
                  </SelectItem>
                  <SelectItem value="pro">
                    <span className="font-medium">Pro</span> -{" "}
                    <span className="text-muted-foreground">
                      $9/month per user
                    </span>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setShowNewTeamDialog(false)}>
            Cancel
          </Button>
          <Button type="submit">Continue</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
