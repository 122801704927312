import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function BuilderHeader() {
    const navigate = useNavigate()

    function onBack(){
        navigate(window.location.toString().substring(0, window.location.toString().indexOf("/new")))
    }

    return (
        <div className="h-full gap-x-1 flex items-center">
            <ArrowLeftIcon 
                className="w-7 h-7 bg-slate-700 hover:bg-slate-500 rounded-full text-slate-400 hover:text-slate-700 p-1" 
                onClick={() => window.history.back()} 
            />
            <h1 className="ml-2 text-white text-md">Interview Builder</h1>
        </div>
    );
}
