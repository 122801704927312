import { useState, useEffect } from 'react'
import Avvvatars from 'avvvatars-react'
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Footer from '../../components/general/Footer';

export default function AcceptOrgInvite(props) {
    const auth = getAuth();
    const user = auth.currentUser
    const socket = props.socket
    const orgID = useParams()["orgID"];
    const [bgImage, setBgImage] = useState("")
    const [orgData, setOrgData] = useState({})
    const [dropdownVisible, setDropdownVisible] = useState(false);


    function pickBG() {
        let options = {
            0: "https://mypeerai.s3.amazonaws.com/green_bonsai.png",
            1: "https://mypeerai.s3.amazonaws.com/red_bonsai.png",
            2: "https://mypeerai.s3.amazonaws.com/shibuya.png",
            3: "https://mypeerai.s3.amazonaws.com/temple.png",
            4: "https://mypeerai.s3.amazonaws.com/cherry_blossom.png",
            5: "https://mypeerai.s3.amazonaws.com/zen_garden.png",
            6: "https://mypeerai.s3.amazonaws.com/night_city.png",
        }

        let selected = Math.floor(Math.random() * 7)
        if (bgImage == "")
            setBgImage(options[selected])
    }

    function handleAccept() {
        var orgMembers = Array.from(orgData.members)
        let thisMember = orgMembers.find((member) => member.email == user.email)
        // If not invited by email
        if(!thisMember){
            thisMember = {}
        }

        thisMember["userID"] = user.uid
        thisMember["role"] = "Member"

        if(!thisMember.email)
            orgMembers.push(thisMember)


        let orgUpdate = {
            'collection': 'organizations',
            'query': {
                'orgID': orgID,
            },
            'update': {
                'members': orgMembers,
                'push': {}
            }
        }
        socket.emit('update-mongo', orgUpdate)
        window.location = "/products"
    }

    useEffect(() => {
        pickBG()

        let orgQuery = {
            'collection': 'organizations',
            'orgID': orgID
        }

        socket.emit("get-mongo", orgQuery)

        const responseHandler = (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)

            if (parsed["type"] == "get-org") {
                if (parsed["data"]) {
                    setOrgData(parsed["data"][0])
                    let isCurrentMember = parsed["data"][0].members.find((member) => member.userID == user.uid) != null
                    socket.off('response', responseHandler)
                    // if(isCurrentMember){
                    //     window.location = "/products"
                    // }
                }
            }
        }
        socket.on('response', responseHandler)
    }, [orgID])

    return (
        <>
            {bgImage != "" &&
                <>
                    <div className="relative h-screen overflow-y-auto">
                        <div className="absolute inset-0 bg-cover overflow-auto blur-md brightness-50" style={{ backgroundImage: `url(${bgImage})` }}></div>
                        <div className={`absolute inset-0 flex flex-col items-center h-full w-full justify-center`}>
                            {
                                <div className="animate-fadeIn flex flex-col justify-center bg-white bg-opacity-5 rounded-lg w-[40%] p-3 gap-4">
                                    <div className="flex items-center justify-center">
                                        {
                                            orgData?.orgImage ?

                                                <img className="h-24 w-24" src={orgData.orgImage} /> :

                                                <Avvvatars className="h-24 w-24" value={orgData?.orgName} />

                                        }
                                    </div>
                                    <div className="flex flex-col items-center justify-center gap-3">
                                        <p className="text-white text-3xl font-semibold">{orgData?.orgName}</p>
                                        <p className="text-gray-400 text-xl font-medium">{orgData?.members?.filter((member) => member.role != "Invited").length + " Members"}</p>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <p className="text-gray-200 text-2xl font-medium">Join your team on Entropi</p>
                                    </div>
                                    <div className="flex items-center justify-center pt-4">
                                        <button className="p-2 px-4 text-white rounded-lg bg-violet-500 w-fit font-bold" onClick={handleAccept}>Accept</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="relative">
                        <Footer />
                    </div>
                </>
            }
        </>
    )
}