import { useState } from "react";
import Preferences from "./Preferences";
import EntropiPro from "./EntropiPro";
import Account from "./Account";
import Organization from "./Organization";

export default function TabView(props) {
    const socket = props.socket;
    const [activeTab, setActiveTab] = useState(2);

    const tabsData = [
        // {
        //     'id': 1,
        //     'name': "Preferences"
        // },
        {
            'id': 2,
            'name': "Account"
        },
        {
            'id': 3,
            'name': "Organization"
        },
        // {
        //     'id': 4,
        //     'name': "Entropi Pro"
        // },
    ];

    return (
        <>
            <div className="flex flex-row items-start justify-center mx-auto px-10 md:gap-8 w-full h-full">
                <div className="flex flex-col w-1/4 rounded-md bg-[#1A2438] p-2 gap-3 h-ful mt-5">
                    <div className="flex-grow gap-y-5">
                        {tabsData.map((tab) => (
                            <button
                                key={tab.id}
                                className={`text-left font-medium w-full text-gray-400 p-4 transition-colors duration-300 mt-2 ${activeTab === tab.id
                                        ? "text-white font-semibold bg-white/5 rounded-md"
                                        : "hover:bg-white/5 md:hover:rounded-md"
                                    }`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                {"  " + tab.name}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="w-3/4 h-full text-white p-5">
                    <div className="bg-[#1A2438] p-3 rounded-lg h-full">
                        {
                            activeTab === 1 ?
                                <Preferences socket={socket}/> :
                                activeTab === 2 ?
                                   <Account socket={socket}/>  :
                                    activeTab === 3 ?
                                        <Organization socket={socket} item={props.curOrg}/> :  
                                        <EntropiPro socket={socket}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
