import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'

export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { currentUser, login, loginWithGoogle, loginWithGithub } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [bgImage, setBgImage] = useState("")

    function pickBG() {
        let options = {
            0: "https://mypeerai.s3.amazonaws.com/christ_the_redeemer.png",
            1: "https://mypeerai.s3.amazonaws.com/barcelona.png",
            2: "https://mypeerai.s3.amazonaws.com/taj_mahal.png",
            3: "https://mypeerai.s3.amazonaws.com/macchu_picchu.png",
            4: "https://mypeerai.s3.amazonaws.com/mount_fuji.png",
            5: "https://mypeerai.s3.amazonaws.com/great_wall.png",
            6: "https://mypeerai.s3.amazonaws.com/greece.png",
        }

        let selected = Math.floor(Math.random() * 7)
        console.log(selected)
        if (bgImage == "")
            setBgImage(options[selected])
    }

    useEffect(() => {
        pickBG()
        if (currentUser) {
            // navigate("/onboarding");
            navigate("/products");
        }
    }, [currentUser, navigate]);

    async function handleLogin(e) {
        e.preventDefault();

        if(email === "" || password === "") {
            setError("Please fill in all fields");
            return;
        }

        try {
            setLoading(true);
            await login(email, password);
            navigate("/onboarding");
        } catch (e) {
            setError("Failed to register");
        }

        setLoading(false);
    }

    async function handleGoogleLogin() {
        try {
            setLoading(true);
            await loginWithGoogle();
            navigate("/products");
        } catch (e) {
            setError("Failed to login with Google");
        }

        setLoading(false);
    }

    async function handleGithubLogin() {
        try {
            setLoading(true);
            await loginWithGithub();
            navigate("/products");
        } catch (e) {
            setError("Failed to login with Github");
        }

        setLoading(false);
    }

    function handleKeyDown(e){
        if(e.key == "Enter"){
            handleLogin(e)
        }
    }

    return (<>

        {bgImage != "" &&
        
        <div className="relative h-screen overflow-y-auto">
            <div className="absolute inset-0 w-screen h-screen bg-cover blur-md brightness-50" style={{ backgroundImage: `url(${bgImage})` }}> </div>
            <div className="absolute inset-0">
                <div className="flex align-center sm:align-start mx-auto p-2 m-2 px-5 pt-3">
                    <svg className="w-10 h-10" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
                        <stop stopColor="#8914DE" offset="0%" />
                        <stop stopColor="#8914DE" offset="25.871%" />
                        <stop stopColor="#338CF5" offset="100%" />
                        </radialGradient>
                    </defs>
                    <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" />
                    </svg>
                    <h1 className='ml-3 mt-1 text-3xl font-bold text-white'>Entropi</h1>
                </div>
            </div>
            <div className="absolute inset-0 justify-center items-center w-full">
                <div className="h-auto w-fit justify-center items-center p-8 m-8 mx-auto">
                    <div id="grid" className="grid grid-cols-1 text-center mt-10">
                        <div className="my-6">
                            <h1 className="my-2 text-white text-6xl font-semibold">Welcome back!</h1>
                            <p className="my-1 text-gray-300 text-xl font-medium">Enter your credentials below to access your workspace.</p>
                        </div>
                        <div className="mx-4 my-2 mt-6 text-center rounded-[5px] backdrop-blur-xl h-min">
                            <input id="email" type="email" className="w-full  p-2 text-sm bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60  text-white" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="mx-4 my-2 text-center rounded-[5px] backdrop-blur-xl h-min">
                            <input id="password" type="password" className="w-full p-2 text-sm bg-transparent border-b-2 border-white border-opacity-30 focus:border-opacity-60 text-white" placeholder="Password" onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                        <div className="mx-4 my-4 text-center rounded-[5px] bg-fuchsia-100 bg-opacity-20 backdrop-blur-xl h-min mx-[8rem]">
                            <button onClick={handleLogin} disabled={loading} className="p-2 text-md font-medium text-white">Sign In</button>
                        </div>
                        <div class="border-t border-white opacity-10 w-full my-2"></div>
                        <div className="mx-4 my-2 text-center rounded-[5px] bg-blue-500 bg-opacity-20 backdrop-blur-xl h-min mx-[8rem]">
                            <button onClick={handleGoogleLogin} disabled={loading} className="p-2 text-md font-medium text-white">Sign In with Google</button>
                        </div>
                        <div className="mx-4 my-2 text-center rounded-[5px] bg-gray-800 bg-opacity-20 backdrop-blur-xl h-min mx-[8rem]">
                            <button onClick={handleGithubLogin} disabled={loading} className="p-2 text-md font-medium text-white">Sign In with Github</button>
                        </div>
                        <div class="border-t border-white opacity-10 w-full my-2"></div>
                        <div className="my-4 text-center">
                            <p className="text-gray-200">Don't have an account? <a href="/signup" className="text-blue-500">Sign Up</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
    );
}