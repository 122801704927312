import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import Tooltip from "@material-tailwind/react/components/Tooltip"
import { CSVLink } from 'react-csv'
import { useOrgState } from "../../../contexts/OrgContext"
const uuid = require('uuid')

export default function CreateLinkModal(props) {
    const showModal = props.showModal
    const setShowModal = props.setShowModal
    const { curOrg } = useOrgState()
    const [linkID, setLinkID] = useState('')
    const [invQuantity, setInvQuantity] = useState(0)
    const [exportData, setExportData] = useState([])
    const [showTooltip, setShowTooltip] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false)
    const [activeTab, setActiveTab] = useState('text');

    const socket = props.socket
    const productID = useParams()["productID"];

    // Generate a single uuid to show in the top text box. New ID every time the modal is opened
    useEffect(() => {
        setLinkID(generateUUIDs(1)[0])
    }, [props.showModal])

    // Generate n uuids
    function generateUUIDs(n) {
        var ids = []
        for (let i = 1; i <= n; i++) {
            ids.push(uuid.v4())
        }
        return ids
    }

    function copyToClipboard() {
        navigator.clipboard.writeText("https://chat.entropi.app/" + linkID)
        setShowTooltip(true);
        setLinkCopied(true)
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    }

    function generateAndExportCSV() {
        if (invQuantity <= 100 && invQuantity != 0) {
            let uuidList = generateUUIDs(invQuantity)
            var data = []
            uuidList.forEach((id, index) => {
                data.push({ "Link ID": index + 1, "Link": "https://chat.entropi.app/" + id })
            })
            setExportData(data)
        }
    }

    function handleClose() {
        // If the user exported links, save them to mongo.
        if(exportData.length > 0){
            for(let key in exportData){
                const url = exportData[key];
                const linkID = url.replace("https://chat.entropi.app/", "");
                var query = {
                    'collection': 'links',
                    'userInfo': {},
                    'interviewID': props.interviewID,
                    'linkID': linkID, // Using the URL as the linkID
                    'orgID': curOrg.orgID,
                    'status': "unopened",
                    'productID': productID,
                    'timestamp': new Date().toLocaleDateString(),
                }
                socket.emit("add-mongo", query);
            }
        }
        // If the user copied the single link, save to mongo
        if(linkCopied){
            var query = {
                'collection': 'links',
                'userInfo': {},
                'interviewID': props.interviewID,
                'linkID': linkID,
                'orgID': curOrg?.orgID,
                'status': "unopened",
                'productID': productID,
                'timestamp': new Date(),
            }
            socket.emit("add-mongo", query)
        }
        // Finally, close modal
        setShowModal(false)
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-5xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-800 outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-4 rounded-t">
                                    <h3 className="text-2xl font-semibold text-gray-200">Invite Interviewees</h3>
                                </div>
                                <div className="relative p-6 flex-col">
                                    <div className="flex justify-center mb-5 text-sm">
                                        <button
                                            className={`px-4 py-2 ${activeTab === 'text' ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'} rounded-l`}
                                            onClick={() => setActiveTab('text')}
                                        >
                                            Text Interview
                                        </button>
                                        <button
                                            className={`px-4 py-2 ${activeTab === 'audio' ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'} rounded-r`}
                                            onClick={() => setActiveTab('audio')}
                                        >
                                            Audio Interview
                                        </button>
                                    </div>
                                    {activeTab === 'text' ? (
                                        <>
                                            <p className="text-gray-300 font-semibold text-sm">Invite a Customer</p>
                                            <div className="relative inline-block my-4 flex flex-row gap-2">
                                                <div className="bg-[#D9D9D9] bg-opacity-80 rounded-md w-fit text-gray-600 hover:text-black leading-relaxed p-1 px-2 text-sm font-medium items-center justify-center" onClick={copyToClipboard}>
                                                    {"https://chat.entropi.app/" + linkID}
                                                    {showTooltip && (
                                                        <div className="bg-green-500 absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 px-4 py-1 bg-gray-800 text-white text-xs rounded">
                                                            Copied!
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="bg-black bg-opacity-30 w-fit rounded-md text-gray-400 hover:text-gray-300 text-sm leading-relaxed px-2 flex items-center justify-center text-center hover:bg-opacity-50" onClick={copyToClipboard}>
                                                    <DocumentDuplicateIcon className="w-5 h-5" />
                                                </div>
                                            </div>
                                            <div className="my-2 flex items-center justify-center">
                                                <div className="flex-grow border-t border-gray-600"></div>
                                                <span className="px-2 text-gray-400">OR</span>
                                                <div className="flex-grow border-t border-gray-600"></div>
                                            </div>
                                            <p className="text-gray-300 font-semibold text-sm pt-4">Export Multiple Invites</p>
                                            <div className="my-4 pt-2 flex gap-5 items-center">
                                                <input type='number' max={100} className="bg-[#D9D9D9] w-24 rounded-md p-2 text-center text-sm placeholder-gray-600 placeholder-opacity-70" placeholder={"Quantity"} onChange={(e) => setInvQuantity(e.target.value)} />
                                                <p className={`text-gray-600 ${invQuantity == 0 ? 'hidden' : ''}`}>→</p>
                                                <CSVLink data={exportData} filename={props.name.replace(" ", "-") + "-entropi-invites"} className={`${invQuantity == 0 ? 'hidden' : ''} bg-black bg-opacity-30 hover:text-gray-300 hover:bg-opacity-50 rounded-md p-2 text-sm text-gray-400 font-medium`} onClick={generateAndExportCSV}>Export CSV</CSVLink>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="text-gray-300 font-semibold text-sm">
                                            <p>Audio interview functionality coming soon!</p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex items-center justify-end p-2 rounded-b gap-2">
                                    <button
                                        className="bg-gray-500 hover:bg-gray-400 hover:text-gray-700 text-white active:bg-gray-700 font-semibold text-sm px-2 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-gray-500 text-white active:bg-gray-700 hover:bg-gray-400 hover:text-gray-700 font-semibold text-sm px-2 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Done
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};