
import { Button } from "../ui/Button"
import { Separator } from "../ui/seperator"
import { CardHeader, CardFooter, Card, CardDescription } from "../ui/card"
import { PencilIcon } from "@heroicons/react/24/solid";

export default function ProductCard(props) {
    const item = props.item
    const setEditItem = props.setEditItem
     return ( 
        <Card className="w-[300px] h-[300px] relative bg-slate-800/40 text-white border-white/10 rounded-lg overflow-hidden">
            <CardHeader className="flex p-3">
                <PencilIcon className="text-slate-700 hover:text-slate-500 w-4 h-4 absolute m-2 top-0 right-0" onClick={() => {setEditItem(item)}}/>
                <div className="flex flex-wrap items-center gap-x-2 w-full mx-2">
                    <div className="rounded-full my-1">
                        {item.imageLink ? <img className="w-12 h-12 object-cover rounded-full" src={item.imageLink} alt="Product Image" /> : null}
                    </div>
                    <h2 className="mt-1 text-2xl font-semibold">{item.name}</h2>
                </div>
            </CardHeader>
            <CardDescription>
                <div className="m-2 px-4 w-[90%]">
                    <p className="text-[13px] text-gray-400 mt-2 mb-4 text-start">{item.desc.substr(0, 150)}{item.desc.length > 150 ? "..." : ""}</p>
                </div>
            </CardDescription>
            <CardFooter className="flex flex-col items-start p-6">
                <div className="absolute inline-block bottom-5 right-5 flex space-x-1">
                    <Button className="bg-[#282846] text-white border-none" onClick={()=>{window.location = `/${item.productID}/profiles`}}><span className="text-xs">Profiles</span></Button>
                    <Separator orientation="vertical" className="text-gray-400" />
                    <Button className="bg-[#282846] text-white border-none text-xs" onClick={()=>{window.location = `/${item.productID}/interviews`}}>Interviews</Button>
                </div>
            </CardFooter>
        </Card>
    );
}
