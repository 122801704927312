import { getAuth } from "firebase/auth";
import { useState, useEffect } from 'react'
import { useOrgState } from "../../contexts/OrgContext";
import Tooltip from "@material-tailwind/react/components/Tooltip"
import { SquaresPlusIcon } from "@heroicons/react/24/outline"
import { Spinner } from "@material-tailwind/react"

import { Button } from "../../components/ui/Button"
import Header from "../../components/app/Header"
import Feedback from "../../components/dashboard/Feedback";
import NoItems from "../../components/dashboard/NoItems";
import ProductCard from "../../components/dashboard/ProductCard";
import NewLibForm from "../../components/product/NewLibForm";



export default function Products(props) {
  const auth = getAuth();
  const user = auth.currentUser
  const socket = props.socket
  const [loading, setLoading] = useState(true);
  const [newItem, setNewItem] = useState(false)
  const [filtered, setFiltered] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [displayList, setDisplayList] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [productList, setProductList] = useState([])
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [startTour, setStartTour] = useState(false);

  const { curOrg } = useOrgState()

  function handleLibAdd() {
      setNewItem(!newItem)
  }

  function handleFilter(e) {
      setSearchText(e.target.value)
      if (e.target.value === "") {
          setFiltered(false)
      }
      setFiltered(true)
  }

  useEffect(() => {
      if (filtered) {
          setDisplayList(productList.filter((product) => {
              return product.name.toLowerCase().includes(searchText.toLowerCase()) || product.desc.toLowerCase().includes(searchText.toLowerCase())
          }))
      }
      else {
          setDisplayList(productList)
      }
  }, [searchText])

  useEffect(() => {
      console.log(user.displayName);
      if (curOrg && curOrg.orgID) {
          var query = {
              'collection': 'products',
              'orgID': curOrg.orgID,
          }
  
          socket.emit("get-mongo", query)
          
          const handleResponse = (data) => {
              data = JSON.stringify(data)
              var parsed = JSON.parse(data)
              if (parsed['type'] == 'get-product') {
                  setProductList(parsed['data'])
                  setDisplayList(parsed['data'])
                  setLoading(false)
              }
          };

          socket.on('response', handleResponse);
          return () => {
              socket.off('response', handleResponse);
          };
      }
  }, [curOrg])
  
  return (
    <>
      <div className="h-screen w-full overflow-hidden flex-col md:flex">
        <Header user={user} modal={dropdownVisible} setModal={setDropdownVisible} socket={socket}/>
        <div className="flex-1 space-y-4">
          <div className="flex items-center justify-between space-y-2">{
          loading ? 
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
                    </div>
                :
                    <div>
                    <Feedback socket={socket}/>
                    {newItem && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center ">
                            <NewLibForm showModal={newItem} setShowModal={setNewItem} socket={socket} curOrg={curOrg} />
                        </div>
                    )}
                    {JSON.stringify(editItem) !== '{}' && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center">
                            <NewLibForm showModal={true} setShowModal={() => setEditItem({})} item={editItem} setItem={setEditItem} socket={socket} cur={curOrg} />
                        </div>
                    )}
                    <div onClick={() => { setDropdownVisible(false) }} className="h-full w-[100vw]">
                        <div className="mt-10 grid grid-cols-1 h-full w-[90vw] max-w-[100vw] mx-auto">
                            <div className="flex flex-col md:flex-row justify-between items-center">
                                <div className="flex flex-row gap-1 items-center">
                                    <SquaresPlusIcon className="w-7 h-7 text-violet-100"/>
                                    <h2 className="text-4xl font-bold text-gray-200">Products</h2>
                                    <Tooltip content="New Product" className="bg-slate-500 text-xs">
                                        <button className="flex justify-center items-center text-lg font-medium text-slate-400 rounded-md bg-slate-700 hover:bg-slate-800 w-6 h-6 ml-1 mt-1.5" onClick={handleLibAdd}>
                                            <span className="sr-only">Add new product</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                            </svg>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="flex w-full md:w-auto justify-end">
                                    <input type="search" className="w-full md:w-64 h-8 focus:outline-none rounded-lg pl-4 pr-3 bg-slate-700 bg-opacity-40 placeholder-slate-500 text-white text-sm font-semibold" placeholder="Search products" onChange={handleFilter} />
                                </div>
                            </div>
                            <div id="content" className={`scrollbar scrollbar-none pt-12 pl-10 h-[85vh] overflow-y-auto scroll-smooth`}>
                                {productList.length === 0 ? 
                                <div className="flex items-center justify-center -mt-20">
                                    <NoItems text={"Get started by adding your first product"} toggle={setNewItem} linkText={"Add Product"} />
                                </div>
                                :
                                    <div className={`flex flex-wrap gap-7 items-center justify-start`}>
                                        {
                                            displayList.map((item, index) => {
                                                console.log(item);
                                                return <ProductCard key={index} item={item} setEditItem={setEditItem} />
                                            })
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}