import { useState } from 'react'
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import Avvvatars from 'avvvatars-react'
import { LinkIcon } from '@heroicons/react/24/solid'
import { CpuChipIcon } from '@heroicons/react/24/outline'

import { useParams } from "react-router-dom";

export default function ProfileHistory(props) {
    const [expanded, setExpanded] = useState(false)
    const productID = useParams()["productID"];
    const item = props.item

    function handleClick() {
        window.location = ('/'+productID+'/interviews?'+item.interviewID)
    }

    return (
        <>
            <div className={`h-auto bg-black bg-opacity-10 rounded-xl p-3 w-[50%] ${expanded ? '' : 'hover:bg-opacity-40'}`} onClick={handleClick}>
                <div className="grid grid-cols-[10%_40%_40%_8%]">
                    <div className="w-8 h-8 p-1 text-gray-600">
                        {
                            item.source == "link" ? <LinkIcon /> : <CpuChipIcon />
                        }
                    </div>
                    <p className="text-sm font-medium text-white text-opacity-90 pt-1 text-center">{item.interviewName}</p>
                    <p className="text-sm font-medium text-gray-400 text-opacity-90 pt-1 text-center">{item.date}</p>
                    <ChevronRightIcon className="mt-1 text-white w-5 h-5" />
                </div>
            </div>

        </>
    )
}