import Avvvatars from 'avvvatars-react'
import { useState } from 'react'

const StatsBar = (props) => {
    return(
        <div className={`w-4 h-2 rounded-xl ${props.color ? props.color : "bg-gray-400 bg-opacity-30"} px-1`}/>
    )
}

export default function ProfileStats(props) {
    const item = props.item
    const ratings = Array(5).fill(null)
    return (<>
    {
        item ? <>
        <div className="bg-black bg-opacity-40 rounded-lg w-[55%]">
            <div className="flex flex-col items-center justify-center p-4 py-6">
                <Avvvatars size={80} value={item.firstName + " " + item.lastName} />
                <p className="text-white text-opacity-80 pt-4 font-bold">{item.firstName + " " + item.lastName}</p>
                <div className="grid grid-rows-2 w-full pt-4 p-2 gap-2 h-full align-bottom">
                    <div className="grid grid-cols-2 items-center ">
                        <p className="text-white text-opacity-50 text-center text-sm font-semibold">Sentiment</p>
                        <div className="flex flex-row gap-1">
                        {
                                ratings.map((val, index) => {
                                    let color;
                                    if(item.metadata.sentiment == 5){
                                         color = "bg-green-600"
                                    }
                                    else if(item.metadata.sentiment == 4){
                                         color = "bg-green-300"
                                    }
                                    else if(item.metadata.sentiment == 3){
                                         color = "bg-yellow-400"
                                    }
                                    else if(item.metadata.sentiment == 2){
                                         color = "bg-orange-400"
                                    }
                                    else{
                                        color = "bg-red-500"
                                    }
                                    
                                    if(index < item.metadata.sentiment){
                                        return <StatsBar key={index} color={color}/>
                                    }
                                    else{
                                        return <StatsBar key={index} />
                                    }
                                })
                        }
                            
                        </div>
                    </div>
                    <div className="grid grid-cols-2 items-center">
                        {/* <p className="text-white text-opacity-50 text-center text-sm font-semibold">Quality</p>
                        <div className="flex flex-row gap-1">
                            {
                                ratings.map((val, index) => {
                                    let color;
                                    if(item.completeness == 5){
                                         color = "bg-green-600"
                                    }
                                    else if(item.completeness == 4){
                                         color = "bg-green-300"
                                    }
                                    else if(item.completeness == 3){
                                         color = "bg-yellow-400"
                                    }
                                    else if(item.completeness == 2){
                                         color = "bg-orange-400"
                                    }
                                    else{
                                        color = "bg-red-500"
                                    }

                                    if(index < item.completeness){
                                        return <StatsBar key={index} color={color}/>
                                    }
                                    else{
                                        return <StatsBar key={index} />
                                    }
                                })
                        }
                        </div> */}
                    </div>
                </div>
            </div>
        </div> 
        </>
        :
        <> </>
}
    </>)
}