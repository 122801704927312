import { useState, useEffect } from 'react'
import Loader from '../../../components/app/Loader'
import AWS from 'aws-sdk'
import Avvvatars from 'avvvatars-react'
import { getAuth } from "firebase/auth";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import Tooltip from '@material-tailwind/react/components/Tooltip';
import { LockClosedIcon, LockOpenIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import DeleteItem from '../../../components/dashboard/DeleteItem';
import { useAuth } from '../../../contexts/AuthContext';


import {Select, SelectTrigger, SelectValue, SelectContent, SelectItem} from "../../../components/ui/select";
import {Input} from "../../../components/ui/input";
import {Button} from "../../../components/ui/Button";




export default function Account(props) {
    const auth = getAuth();
    const user = auth.currentUser
    const socket = props.socket

    const [isEditing, setIsEditing] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [imageLink, setImageLink] = useState("")
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [resetAccount, setResetAccount] = useState(false);
    const [deleteAccount, setDeleteAccount] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");

    const { deleteUser } = useAuth();

    // Preferences
    const [interviewers, setInterviewers] = useState(null)

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    })

    const s3 = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME }
    })

    function uploadFile() {
        if (selectedFile != null) {
            var extension = '.' + selectedFile.name.split('.').pop();
            var fileName = Math.random().toString(36).substring(7) + new Date().getTime() + extension;

            var params = {
                Key: fileName,
                Body: selectedFile,
                ContentType: selectedFile.type
            }
            setUploadInProgress(true)
            s3.putObject(params, (err, data) => {
                if (err) {
                    console.log(err)
                    console.error("File was not uploaded successfully")
                }
                else {
                    setUploadInProgress(false)
                    setImageLink(`https://mypeerai.s3.amazonaws.com/${encodeURIComponent(fileName)}`)
                    setSelectedFile(null)
                }
            })
        }
    }


    function handleFileChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0])
        }
    }

    function locksmith() {
        if (!isEditing) {
            setIsEditing(true)
        }
        else {
            let updateData = {
                'collection': 'users',
                'query': {
                    'userID': user.uid
                },
                'update': {
                    'firstName': firstName,
                    'lastName': lastName,
                    'email': email,
                    'userName': username,
                    'imageLink': imageLink
                }
            }

            socket.emit('update-mongo', updateData)
            setIsEditing(false)
        }
    }

    useEffect(() => {
        let userQuery = {
            'collection': 'users',
            'userID': user.uid
        }


        socket.emit('get-mongo', userQuery)

        // var interviewersQuery = {
        //     'collection': 'interviewers',
        //     '$or': [
        //         {'userID': user.uid},
        //         {'public': true}
        //     ]
        // }
        // socket.emit("get-mongo", interviewersQuery)

        const responseHandler = (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)
            if (parsed["type"] == "get-user") {
                let thisUser = parsed["data"][0]
                if (thisUser) {
                    setFirstName(thisUser.firstName)
                    setLastName(thisUser.lastName)
                    setEmail(thisUser.email)
                    setUsername(thisUser.userName)
                    setImageLink(thisUser.imageLink)
                }
            }
            // if (parsed["type"] == "get-interviewer") {
            //     let interviewerList = parsed["data"]
            //     if (interviewerList) {
            //         setInterviewers(interviewerList)
            //     }
            // }
        }

        socket.on('response', responseHandler)
    }, [])

    function handleReset() {
        socket.emit('reset-account', { 'userID': user.uid })
    }

    function handleDelete() {

    }

    return (<>
        <div className="relative">
            <div className="w-full grid grid-cols-1 m-4 p-4 max-h-[32rem] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-xl scrollbar-thumb-black">
                <p className="text-white font-bold text-xl">Account Details</p>
                <div className="grid grid-cols-[30%_70%] gap-x-10 pt-6">
                    <div id="picture" className="flex flex-col items-center justify-center text-xs">
                        <div className="">
                            {
                                !imageLink && !uploadInProgress ?
                                    user.photoURL.length === 0 ? <Avvvatars value={user.displayName} size={150} /> : <img src={user?.photoURL} className="object-cover rounded-full w-[8rem] h-[8rem]" />
                                    :
                                    uploadInProgress ?
                                        <div className="flex items-center justify-center rounded-full bg-slate-900 w-[9.5rem] h-[9.5rem]"><Loader /></div> :
                                        <img src={imageLink} className="object-cover rounded-full w-[9.5rem] h-[9.5rem]" />
                            }
                        </div>
                        <div className="flex flex-row items-center justify-center pt-4 gap-6 text-xs">
                            <label className={` ${!isEditing ? "bg-black bg-opacity-20" : "bg-white bg-opacity-10 hover:bg-opacity-30 hover:font-bold"} p-2 font-medium rounded-md text-sm`}>
                                <span>Change</span>
                                <input type="file" className="hidden" onChange={handleFileChange} disabled={!isEditing} />
                            </label>
                            <button className="bg-white bg-opacity-10 hover:bg-opacity-30 hover:font-bold disabled:bg-opacity-20 disabled:bg-black disabled:font-medium p-2 font-medium rounded-md text-sm" disabled={(selectedFile || imageLink) && isEditing ? false : true} onClick={selectedFile ? uploadFile : () => setImageLink("")}>{selectedFile ? "Upload" : "Remove"}</button>
                        </div>
                    </div>
                    <div id="inputs" className="flex flex-wrap gap-6 text-xs">
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-semibold text-gray-200">First Name</p>
                            <Input className="w-56 p-2 rounded-md text-white bg-[#263753] border-gray-400/10" placeholder="First Name" disabled={!isEditing} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-semibold text-gray-200">Last Name</p>
                            <Input className="w-56 p-2 rounded-md text-white bg-[#263753] border-gray-400/10" placeholder="Last Name" disabled={!isEditing} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-semibold text-gray-200">Email</p>
                            <Input className="w-56 p-2 rounded-md text-white bg-[#263753] border-gray-400/10" placeholder="Email" disabled={!isEditing} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-semibold text-gray-200">Username</p>
                            <Input className="w-56 p-2 rounded-md text-white bg-[#263753] border-gray-400/10" placeholder="Username" disabled={!isEditing} value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start pt-10 gap-4">
                    <p className="text-white font-bold text-xl ">Preferences</p>
                    <Tooltip content="Set your default configuration" placement='right' className="bg-gray-500 text-xs text-white text-center">
                        <QuestionMarkCircleIcon className="w-5 h-5 justify-center text-gray-500 hover:text-gray-400" />
                    </Tooltip>
                </div>
                <div className="pt-6 text-sm">
                    <div className="flex flex-row items-center justify-start gap-5">
                        <div className="relative flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-30 rounded-xl p-4 shadow-xl">
                            <Select disabled={!isEditing} className='border-white/10'>
                                <SelectTrigger className="w-44 text-center p-1 px-2 rounded-md bg-[#263753]">
                                    <SelectValue placeholder="gpt-3.5-turbo" />
                                </SelectTrigger>
                                <SelectContent className="bg-slate-900 mt-3">
                                    <SelectItem value="gpt-3.5-turbo">gpt-3.5-turbo</SelectItem>
                                    <SelectItem value="gpt-4-turbo">gpt-4-turbo</SelectItem>
                                    <SelectItem value="gpt-4o">gpt-4o</SelectItem>
                                </SelectContent>
                            </Select>
                            <p className="pt-4 font-medium text-white text-opacity-80">Model</p>
                        </div>
                        <div className="relative flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-30 rounded-xl p-4 shadow-xl">
                            <Select disabled={!isEditing}>
                                <SelectTrigger className="w-44 text-center p-1 px-2 rounded-md bg-[#263753]">
                                    <SelectValue placeholder="Eve" />
                                </SelectTrigger>
                                <SelectContent className="bg-slate-900 mt-3">
                                    <SelectItem value="d3422899-0151-49ea-ab43-6c88f2281368">Eve</SelectItem>
                                </SelectContent>
                            </Select>
                            <p className="pt-4 font-medium text-white text-opacity-80">Interviewer</p>
                        </div>
                        <div className="relative flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-30 rounded-xl p-4 shadow-xl">
                            <Select disabled={!isEditing}>
                                <SelectTrigger className="w-44 text-center p-1 px-2 rounded-md bg-[#263753]">
                                    <SelectValue placeholder="4 Weeks" />
                                </SelectTrigger>
                                <SelectContent className="bg-slate-900 mt-3">
                                    <SelectItem value={4}>4 Weeks</SelectItem>
                                    <SelectItem value={5}>5 Weeks</SelectItem>
                                    <SelectItem value={6}>6 Weeks</SelectItem>
                                    <SelectItem value={7}>7 Weeks</SelectItem>
                                    <SelectItem value={8}>8 Weeks</SelectItem>
                                </SelectContent>
                            </Select>
                            <p className="pt-5 font-medium text-white text-opacity-80">Invite Validity</p>
                        </div>
                    </div>
                </div>
                <p className="text-xl pt-10 font-bold">Danger Area</p>
                <div className="flex flex-row pt-6 gap-4 text-xs">
                    <Button 
                        variant="destructive" 
                        size="default" 
                        className="w-36 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-300 ease-in-out transform hover:scale-105" 
                        disabled={!isEditing} 
                        onClick={() => setResetAccount(true)}
                    >
                        Reset Account
                    </Button>
                    <Button 
                        variant="destructive" 
                        size="default" 
                        className="w-36 bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded shadow-md transition duration-300 ease-in-out transform hover:scale-105" 
                        disabled={!isEditing} 
                        onClick={() => setDeleteAccount(true)}
                    >
                        Delete Account
                    </Button>
                </div>
                <div className="absolute right-0 bottom-0 -mb-7 p-3">
                    {
                        isEditing ? <LockOpenIcon className="h-5 w-5 text-yellow-400" onClick={locksmith} /> : <LockClosedIcon className="h-5 w-5 text-yellow-400" onClick={locksmith} />
                    }
                </div>

                <DeleteItem socket={socket} isOpen={deleteAccount} closeModal={() => setDeleteAccount(false)} handleDelete={deleteUser} action={"delete_account"} />
                <DeleteItem socket={socket} isOpen={resetAccount} closeModal={() => setResetAccount(false)} handleDelete={handleReset} action={"reset_account"} />
            </div>
            <div className="absolute left-0 bottom-0 -mb-7 p-3">
                {
                    isEditing ?
                        <div className="flex flex-row items-center justify-center gap-2">
                            <ExclamationTriangleIcon className="h-5 w-5 text-orange-400" onClick={locksmith} />
                            <p className="text-sm text-slate-400">Caution - you may have unsaved changes</p>
                        </div>
                        :
                        <></>
                }
            </div>
        </div>

    </>)
}
