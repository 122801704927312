import { useState, useEffect } from 'react'
import { getAuth } from "firebase/auth";
import Header from "../../components/general/Header";
import HowItWorks from './components/HowItWorks';
import OnboardingForm from './components/OnboardingForm';

export default function Onboarding(props) {
    const socket = props.socket
    const auth = getAuth();
    const user = auth.currentUser

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [step, setStep] = useState(1)
    const [isReturnUser, setIsReturnUser] = useState(null)

    function handleNext(){
        if (step === 1){
            setStep(2)
        }
        if (step === 2){
            window.location = '/products'
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function handleScroll(event){
        event.preventDefault()
    }

    useEffect(() => {
        let userID = user.uid
        if(user.uid) {
            let userQuery = {
            'collection': 'users',
            'userID': user.uid
        }
        socket.emit('get-mongo', userQuery)
        }

        socket.on('response', (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)

            if(parsed["type"] == "get-user"){
                var userResult = parsed["data"][0]?.userID == user.uid
                if (userResult){
                    window.location = "/products"
                }
                else{
                    setIsReturnUser(false)
                }
            }
        })
    }, [user.uid])

    return (<>
            {isReturnUser != null && !isReturnUser &&  
            <div className="backdrop-blur-xl bg-gradient-to-t from-[#070E25] to-black h-screen w-screen overflow-y-auto" onScroll={handleScroll} onKeyDown={handleKeyDown}>
            <Header user={user.email} modal={dropdownVisible} setModal={setDropdownVisible} onboarding={true} socket={socket}/>
            <div className="h-full w-full">
            {
                step === 1 ? 
                <HowItWorks next={handleNext}/>
                :
                <OnboardingForm socket={socket} next={handleNext}/>
            }
            </div>
        </div>}
    </>)
}