import { useEffect, useState } from 'react'
import { getAuth } from "firebase/auth";

export default function Preferences(props){
    const socket = props.socket
    const auth = getAuth();
    const user = auth.currentUser
    const [isEditing, setIsEditing] = useState(false);
    const [interviewers, setInterviewers] = useState(null)


    useEffect(() => {
        var interviewersQuery = {
            'collection': 'interviewers',
            'userID': user.uid
        }
        socket.emit("get-mongo", interviewersQuery)
        socket.on('response', (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)
            if (parsed['type'] === 'get-interviewer') {
                setInterviewers(parsed['data']);
            }
        })
    }, [isEditing])

    return(<>
        <div className="grid grid-cols-1 m-4 p-4">
            <div id="modelPreference" className="flex flex-col m-4 p-4 items-start">
                <p className="text-lg font-semibold text-white ml-1">Preferred Model</p>
                <select className="form-select mt-2 block w-fit bg-black/30 p-1 rounded-md">
                    <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                    <option value="gpt-4">gpt-4</option>
                </select>
            </div>
            <div id="modelPreference" className="flex flex-col m-4 p-4 items-start">
                <p className="text-lg font-semibold text-white ml-1">Preferred Interviewer</p>
                <select className="form-select mt-2 block w-fit bg-black/30 p-1 rounded-md">
                    <option value="gpt-3.5-turbo">Eve (Default)</option>
                    <option value="gpt-4">gpt-4</option>
                </select>
            </div>
            <div id="inviteLink" className="flex flex-col mx-4 px-4 items-start">
                <p className="text-lg font-semibold text-white ml-1">Invite Link Expiry</p>
                <select className="form-select mt-2 block w-fit bg-black/30 p-1 rounded-md">
                    <option value="2">2 weeks</option>
                    <option value="4">4 weeks</option>
                    <option value="6">6 weeks</option>
                    <option value="8">8 weeks</option>
                </select>
            </div>
        </div>
    
    </>)
}