'use client'
import { useAuth } from '../../contexts/AuthContext'
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useOrgState } from "../../contexts/OrgContext.js";
import ReactDOM from 'react-dom';
import Avvvatars from 'avvvatars-react'
import { GlobeAmericasIcon } from '@heroicons/react/24/solid';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

export default function Header(props) {
  const auth = getAuth();
  const user = auth.currentUser

  const { curOrg, setCurOrg, orgList } = useOrgState()
  const [top, setTop] = useState(true)
  const [modal, setModal] = useState(false);


  const dropdownVisible = props.modal
  const setDropdownVisible = props.setModal
  const [imageLink, setImageLink] = useState();
  const { logout, setError } = useAuth();
  const navigate = useNavigate();
  const socket = props.socket

  async function handleLogout() {
    try {
      setError("");
      await logout();
      setModal(false);
      navigate("/");
    } catch {
      setError("Failed to logout");
    }
  }

  function handleOrgChange(e) {
    const selectedOrg = orgList.find(org => org.orgName === e.target.value);
    setCurOrg(selectedOrg);
    sessionStorage.setItem('curOrg', JSON.stringify(selectedOrg));
  }


  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true)
  }

  useEffect(() => {
    let userQuery = {
      'collection': 'users',
      'userID': props.user?.uid
    }
    socket?.emit('get-mongo', userQuery)
    const responseHandler = (data) => {
      data = JSON.stringify(data)
      var parsed = JSON.parse(data)
      if (parsed["type"] == "get-user") {
        let thisUser = parsed["data"][0]
        if (thisUser) {
          setImageLink(thisUser.imageLink)
        }
        socket?.off('response', responseHandler)
      }
    }
    socket?.on('response', responseHandler)
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => {window.removeEventListener('scroll', scrollHandler)}

  }, [top])

  function toggleDropdown() {
    props.setModal(!props.modal)
  }

  return (
    <div className={`w-full ${props.onboarding || props.compliance ? 'bg-transparent' : 'bg-slate-900 border-b-2 border-slate-300/5 mb-2'} transition duration-300 ease-in-out backdrop-blur-sm pb-1 overflow-y-hidden brightness-100`}>
      <div className="max-w-8xl">
        <div className={`flex items-center justify-between h-[10px] p-2 mx-[2rem] mt-5 mb-3`}>
          {/* Site branding */}
          <div className="flex flex-row items-center justify-center gap-1">
            <div className="flex flex-row align-center sm:align-start mx-auto gap-2">
            <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
                  <stop stopColor="#8914DE" offset="0%" />
                  <stop stopColor="#8914DE" offset="25.871%" />
                  <stop stopColor="#338CF5" offset="100%" />
                </radialGradient>
              </defs>
              <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" />
            </svg>
            <h1 className='items-center my-auto text-lg font-bold text-white'>Entropi</h1>
          </div>
          <div className={orgList && !props.compliance && !props.onboarding ? `flex flex-wrow` :  `hidden`}>
            <BuildingOffice2Icon className='h-5 w-5 text-white ml-5'/>
            <select 
              className={`${props.onboarding || props.compliance ? 'hidden' : ''} w-44 rounded-lg text-violet-50 bg-transparent my-auto focus:outline-none truncate text-xs font-bold`} 
              onChange={handleOrgChange}
              disabled={props.disableOrgSelect ? true : false}
            >
              {
                orgList?.map((org, index) => {
                  return (
                    <option key={index} value={org.orgName} selected={org?.orgName == curOrg?.orgName}>
                      {org?.orgName}
                    </option>
                  )
                })
              }
            </select>
          </div>
          </div>
          


          {/* Desktop navigation */}
          <nav className="hidden mt-1 md:flex md:grow">
            {/* Desktop sign in links */}
            <ul className="flex row grow justify-end flex-wrap items-center p-4 gap-5">
              <li className={`${props.onboarding || props.compliance ? 'hidden' : ''}`}>
                <a href="/products" className="rounded-lg text-gray-200">
                  <p className="mt-[1px] text-sm font-medium hover:font-bold">Products</p>
                </a>
              </li>
              <li>
                <div className={`${props.compliance || props.onboarding ? "hidden" : ''} relative`}>
                  <button
                    onClick={toggleDropdown}
                    className="focus:outline-none"
                  >
                    {
                      imageLink ? <img src={imageLink} className="object-fit rounded-full h-8 w-8" /> : props.user?.photoURL ? <img src={props.user?.photoURL} className="object-cover rounded-full h-7 w-7" /> : <Avvvatars value={props.user?.displayName} />
                    }
                  </button>
                </div>
              </li>
            </ul>

          </nav>

          {/* <MobileMenu /> */}

        </div>
        {dropdownVisible && ReactDOM.createPortal(
          <div className="absolute top-10 mr-10 right-0 w-[150px] mt-3 bg-gray-700 bg-opacity-100 rounded-md shadow-lg z-50">
            {/* <button
                className="block w-[100%] text-left m-5 text-sm text-gray-500 hover:text-gray-100"
                onClick={() => (window.location = "/settings")}
                >
                <p className="font-medium">Settings</p>
                </button> */}
            <button
              className="block w-min text-left m-5 text-sm text-gray-300 hover:text-gray-100"
              onClick={() => window.location = "/settings"}
            >
              <p className="font-medium">Settings</p>
            </button>
            <button
              className="block w-min text-left m-5 text-sm text-gray-300 hover:text-gray-100"
              onClick={handleLogout}
            >
              <p className="font-medium">Logout</p>
            </button>
          </div>,
          document.body
        )}
      </div>
    </div>
  )
}
