import React, { useState, useEffect } from "react";
import { Button } from "../ui/Button";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../ui/card";
import { PhotoIcon } from "@heroicons/react/24/outline"
import { getAuth } from "firebase/auth";
import Loader from "../app/Loader";
import AWS from 'aws-sdk'
const uuid = require('uuid')



function XIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}

export default function NewLibForm(props) {
    const showModal = props.showModal
    const setShowModal = props.setShowModal
    const editItem = props.item
    const setEditItem = props.setItem
    const [selectedFile, setSelectedFile] = useState(null)
    const [uploadDisabled, setUploadDisabled] = useState(true)
    const [imageLink, setImageLink] = useState("")
    const [productID, setProductID] = useState("")
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false)
    const [name, setName] = useState("")
    const [desc, setDesc] = useState("")
    const [url, setURL] = useState("")
    const auth = getAuth();
    const user = auth.currentUser
    const socket = props.socket

    useEffect(() => {
        if (editItem) {
            setImageLink(editItem.imageLink)
            setName(editItem.name)
            setDesc(editItem.desc)
            setURL(editItem.url)
            setProductID(editItem.productID)
        }
        else {
            setProductID(uuid.v4())
        }
    }, [editItem])

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    })

    const s3 = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME }
    })

    function uploadFile() {
        if (selectedFile != null) {
            var extension = '.' + selectedFile.name.split('.').pop();
            var fileName = Math.random().toString(36).substring(7) + new Date().getTime() + extension;

            var params = {
                Key: fileName,
                Body: selectedFile,
                ContentType: selectedFile.type
            }
            setUploadInProgress(true)
            s3.putObject(params, (err, data) => {
                if (err) {
                    console.log(err)
                    console.error("File was not uploaded successfully")
                }
                else {
                    setUploadInProgress(false)
                    setImageLink(`https://mypeerai.s3.amazonaws.com/${encodeURIComponent(fileName)}`)
                    setUploadDisabled(true)
                }
            })
        }
    }

    function handleSave() {
        if (document.getElementById('productName').value.length > 0 && document.getElementById('productDesc').value.length > 0){
            var data = {
                'collection': 'products',
                'query': {
                    'productID': productID
                },
                'update': {
                    'name': document.getElementById('productName').value,
                    'desc': document.getElementById('productDesc').value,
                    'url': document.getElementById('productURL').value,
                    'imageLink': imageLink,
                    'userID': user.uid,
                    'orgID': props.curOrg?.orgID,
                }
            }
            socket.emit('update-mongo', data)
            window.location.reload()
        }
        else {
            alert("Please ensure you've added a name and description")
        }
    }


    function handleClose() {
        setSelectedFile(null)
        setImageLink("")
        setProductID(uuid.v4())
        document.getElementById('productName').value = ""
        document.getElementById('productDesc').value = ""
        document.getElementById('productURL').value = ""
        setShowModal(false)
        if (setEditItem) {
            setEditItem({})
        }
    }

    function handleFileChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0])
            setUploadDisabled(false)
        }
    }

    return (
        <>
            {showModal ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                    <Card className="w-full relative max-w-lg bg-slate-800 border-none backdrop-blur-xl text-white rounded-lg shadow-xl">
                        <CardHeader className="border-b border-gray-700 p-4">
                            <div className="flex justify-between items-center">
                                <CardTitle>{props.item ? "Edit" : "New"} Product</CardTitle>
                                <Button className="text-gray-400" variant="ghost" onClick={handleClose}>
                                    <XIcon className="w-4 h-4" />
                                </Button>
                            </div>
                        </CardHeader>
                        <CardContent className="p-4">
                            <div className="grid grid-cols-2 gap-8">
                                <div className="flex flex-col space-y-4">
                                    <label className="font-semibold" htmlFor="image">Image</label>
                                    <div className="flex flex-col items-center space-y-2">
                                        <img className={`h-24 w-24 rounded-full bg-white/70`} src={imageLink} style={{ aspectRatio: "100/100", objectFit: "cover" }} />
                                        <div className="flex space-x-3">
                                            <label className="bg-slate-900 p-2 rounded-lg text-gray-300 text-sm cursor-pointer text-center">                                        Select
                                                <input type="file" className="hidden" onChange={handleFileChange} />
                                            </label>
                                            <button disabled={uploadDisabled} onClick={uploadFile} className="bg-slate-900 p-2 rounded-lg text-gray-300 text-sm disabled:bg-opacity-50 disabled:bg-slate-700">Upload</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-4">
                                    <label className="font-semibold" htmlFor="product">Product</label>
                                    <input id="productName" type="text" className="bg-[#1e2128] border border-gray-600 rounded-md p-2 px-3 text-sm text-white font-medium" required={true} placeholder="Product Name" defaultValue={name} />
                                    <textarea id="productDesc" className="bg-[#1e2128] border border-gray-600 rounded-md p-2 text-sm text-white font-medium h-[20vh]" required={true} placeholder="Provide a detailed description of your product" defaultValue={desc} />
                                    <input id="productURL" type="url" className="bg-[#1e2128] border border-gray-600 rounded-md p-2 px-3 text-sm text-white font-medium" placeholder="URL (Not Required)" defaultValue={url} />
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter className="border-t border-gray-700 p-6 w-full items-end">
                            <button className="w-fit absolute bottom-2 right-5 bg-gray-500 text-white active:bg-gray-700 font-semibold text-sm px-2 py-1 rounded shadow hover:bg-slate-700 outline-none focus:outline-none ease-linear transition-all duration-150" onClick={handleSave}>Save</button>
                        </CardFooter>
                    </Card>
                </div>
                    </>
            ) : null}
        </>
    );
};