import { useState, useEffect } from 'react';
import ProfileStats from './ProfileStats';
import ProfileHistory from './ProfileHistory';

export default function ProfileViewer(props) {

    const socket = props.socket
    const item = props.curItem
    const [interviews, setInterviews] = useState([])
    const [viewThread, setViewThread] = useState(false)
    const [selectedAgent, setSelectedAgent] = useState({})
    var linkData = []
    const [history, setHistory] = useState([])

    useEffect(() => {

        console.log("ProfileViewer: ", item);

        
        var histIDs = item.history.map(hist => { return hist.id })
        var histTypes = item.history.map(hist => { return hist.type })

        let linkQuery = {
            'collection': 'links',
            'linkID': { "$in": histIDs }
        }
        socket.emit('get-mongo', linkQuery)

        socket.on('response', (data) => {
            data = JSON.stringify(data)
            var parsed = JSON.parse(data)

            if (parsed['type'] === 'get-links') {
                linkData = parsed['data']
                var intIDs = linkData.map(data => { return data.interviewID })
                let interviewQuery = {
                    'collection': 'interviews',
                    'interviewID': { "$in": intIDs }
                }
                socket.emit('get-mongo', interviewQuery)
            }
            if (parsed['type'] === 'get-interviews') {
                let interviewData = parsed['data']
                let histList = []
                interviewData.map((interview, index) => {
                    let hist_entry = {}
                    let linkEntry = linkData.find(item => { return item.interviewID === interview.interviewID })
                    hist_entry['interviewName'] = interview.interviewName
                    hist_entry['interviewID'] = interview.interviewID
                    hist_entry['source'] = histTypes[index]
                    hist_entry['date'] = linkEntry.completed_on
                    histList.push(hist_entry)
                })
                setHistory(histList)
            }
        })
    }, [item])

    function handleThreadView(agent) {
        setViewThread(true)
        setSelectedAgent(agent)
    }

    return (<> {item.metadata &&
        <section className="w-full h-full p-5 m-auto grid grid-rows-[40%_60%] overflow-y-auto">
            <div className="grid grid-cols-1 flex h-full w-full divide-y divide-dashed divide-white divide-opacity-10">
                <div className="flex flex-col m-4 p-4">
                    <p className="text-xl font-semibold text-white text-opacity-80">Summary</p>
                    <div className="grid grid-cols-[50%_50%] w-full h-full pt-8">
                        <div className="grid grid-rows-2 items-start gap-6">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-4 shadow-xl">
                                    <p className="pt-5 text-md font-medium text-white text-opacity-90">{item.metadata.source}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Source</p>
                                </div>
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-4 shadow-xl">
                                    <p className="pt-5 text-md font-medium text-white text-opacity-90">{item.metadata.acquired_date}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Acquired</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-4 shadow-xl">
                                    <p className="pt-5 text-md font-medium text-white text-opacity-90">{item.metadata.interview_count}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Interviews</p>
                                </div>
                                <div className="flex flex-col flex-wrap items-center justify-center bg-black bg-opacity-20 rounded-xl p-4 shadow-xl">
                                    <p className="pt-5 text-md font-medium text-white text-opacity-90">{item.metadata.last_interviewed}</p>
                                    <p className="pt-5 text-xs font-medium text-white text-opacity-60">Last Interviewed</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full h-full">
                            <ProfileStats item={item} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col m-4 p-4">
                    <p className="text-xl font-semibold text-white text-opacity-80">Details</p>
                    <div className="grid grid-cols-[50%_50%] w-full h-full pt-8">
                        <div className="flex flex-col gap-4">
                            <div className="h-32">
                                <div className="grid grid-cols-2 items-center">
                                    <p className="font-medium text-white text-opacity-50 text-sm">Occupation</p>
                                    <p className="font-medium text-white text-opacity-50 text-sm">Location</p>
                                </div>
                                <div className="grid grid-cols-2 pt-5">
                                    <p className="text-white text-opacity-70 w-48 bg-black bg-opacity-30 p-3 rounded-md text-sm text-start">{item.details.occupation.split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}</p>
                                    <p className="text-white text-opacity-70 bg-black w-48 bg-opacity-30 p-3 rounded-md text-sm text-start">{item.details.location}</p>
                                </div>
                            </div>

                            <div className="items-end">
                                <div className="grid grid-cols-2 items-center">
                                    <p className="font-medium text-white text-opacity-50 h-fit text-sm">Key Values</p>
                                    <p className="font-medium text-white text-opacity-50 h-fit text-sm">Interests</p>
                                </div>
                                <div className="grid grid-cols-2 pt-3">
                                    <div className="flex flex-col">
                                        <ul className="bg-black bg-opacity-20 rounded-md mt-1 w-52">
                                            {
                                                item.details.key_values.map((value, key) => {
                                                    return <li className="text-white text-opacity-70 p-3 whitespace-normal text-sm" key={key}>{value.split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}</li>
                                                })

                                            }
                                        </ul>
                                    </div>
                                    <div className="flex flex-col">
                                        <ul className="bg-black bg-opacity-20 rounded-md mt-1 w-52">
                                            {
                                                item.details.interests.map((value, key) => {
                                                    return <li className="text-white text-opacity-70 p-3 whitespace-normal text-sm" key={key}>{value.split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}</li>
                                                })

                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <p className="font-medium text-white text-opacity-50 h-fit text-sm">Background Summary</p>
                            <div className="bg-black bg-opacity-20 rounded-lg p-2 w-[90%]">
                                <p className="text-white text-opacity-70 p-1 whitespace-normal text-xs leading-normal">{item.details.background}</p>
                            </div>
                            <p className="font-medium text-white text-opacity-50 pt-2 h-fit text-sm">Product Alignment</p>
                            <div className="bg-black bg-opacity-20 rounded-lg p-2 w-[90%]">
                                <p className="text-white text-opacity-70 p-1 whitespace-normal text-xs leading-normal">{item.details.productAlignment}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col m-4 p-4">
                    <p className="text-xl font-semibold text-white text-opacity-80">History</p>
                    <div className="flex flex-col pt-8 gap-1">
                        {
                            history.map((interview, index) => {
                                return <ProfileHistory key={index} item={interview} />
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    }
    </>
    )
}