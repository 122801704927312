import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "../../../contexts/OrgContext";
import { DocumentDuplicateIcon, EnvelopeIcon, CheckCircleIcon } from "@heroicons/react/24/outline"
import Tooltip from "@material-tailwind/react/components/Tooltip"
import { CSVLink } from 'react-csv'
const uuid = require('uuid')

export default function OrgInvite(props) {
    const showModal = props.showModal
    const setShowModal = props.setShowModal
    const socket = props.socket
    const { curOrg } = useOrgState()
    const orgID = curOrg?.orgID

    const [showTooltip, setShowTooltip] = useState(false);
    const [invited, setInvited] = useState(false);
    const [searchText, setSearchText] = useState("")


    function copyToClipboard() {
        navigator.clipboard.writeText("https://entropi.app/orgs/" + curOrg.orgID + "/join")
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    }

    function sendEmails() {
        let filter = /[^a-zA-Z0-9@._\-;]/g;
        let cleanedText = searchText.replace(filter, "");
        let addressList = cleanedText.split(";");
        socket.emit('org-invite', { "orgName": curOrg?.orgName, "emails": addressList, "link": "https://entropi.app/orgs/" + curOrg.orgID + "/join" })
        setSearchText("")
        setInvited(true)

        let newMembers = []
        addressList.map((email) => {
            let entry = {
                'email': email,
                'userID': '',
                'role': "Invited"
            }
            newMembers.push(entry)
        })
        console.log(newMembers)

        newMembers.map((member) => {
            let orgUpdate = {
                'collection': 'organizations',
                'query': {
                    'orgID': curOrg.orgID,
                },
                'update': {
                    'push': {
                        'members': member
                    }
                }
            }
            socket.emit('update-mongo', orgUpdate)
        })


        setTimeout(() => {
            setInvited(false)
        }, 1700)
    }

    function handleClose() {
        // If the user exported links, save them to mongo.
        // Finally, close modal
        setShowModal(false)
    }

    return (
        <>
            {showModal ? (
                <>
                    <div>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto max-w-6xl">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-800 outline-none focus:outline-none">
                                    <div className="flex items-start justify-between p-4">
                                        <h3 className="text-2xl font-semibold text-gray-200">Invite Members</h3>
                                    </div>
                                    <div className="relative p-6 flex-col">
                                        <p className="text-gray-300 font-semibold text-sm">Share via URL</p>
                                        <div className="relative inline-block my-4 flex flex-row gap-2">
                                            <div className="bg-white bg-opacity-10 rounded-md w-fit text-gray-400 leading-relaxed p-1 px-3 text-sm font-medium items-center justify-center" onClick={copyToClipboard}>
                                                {"https://entropi.app/orgs/" + curOrg.orgID + "/join"}
                                                {showTooltip && (
                                                    <div className="bg-green-500 absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 px-4 py-1 bg-gray-800 text-white text-xs rounded">
                                                        Copied!
                                                    </div>
                                                )}
                                            </div>
                                            <div className="bg-black bg-opacity-30 w-fit rounded-md text-gray-400 hover:text-gray-300 text-sm leading-relaxed px-2 flex items-center justify-center text-center hover:bg-opacity-50" onClick={copyToClipboard}>
                                                <DocumentDuplicateIcon className="w-5 h-5" />
                                            </div>
                                        </div>
                                        <div className="my-2 flex items-center justify-center">
                                            <div className="flex-grow border-t border-gray-600"></div>
                                            <span className="px-2 text-gray-400">OR</span>
                                            <div className="flex-grow border-t border-gray-600"></div>
                                        </div>
                                        <p className="text-gray-300 font-semibold text-sm">Share via Email</p>
                                        <div className="flex flex-row gap-2 pt-3">
                                            <input type="text" className="focus:outline-none bg-white w-full bg-opacity-5 rounded-lg px-3 p-1" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeHolder={"Semicolon separated emails"} />
                                            <button className="p-2 px-3 bg-black bg-opacity-30 w-fit rounded-md text-gray-400 hover:text-gray-300 leading-relaxed flex items-center justify-center text-center hover:bg-opacity-50" onClick={sendEmails}>{invited ? <CheckCircleIcon className="text-green-500 h-4 w-4" /> : <EnvelopeIcon className="h-4 w-4" />}</button>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end p-2">
                                        <button className="p-1 px-2 text-sm bg-gray-600 hover:bg-gray-700 text-white rounded-md" onClick={() => setShowModal(false)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-60 fixed inset-0 z-40 bg-black" onClick={() => setShowModal(false)}></div>
                    </div>
                </>
            ) : null}
        </>
    );
};